import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//GET LIST VOOUCHER
export const getVoucherList = createAsyncThunk('voucher/list', async (parameter) => {
  const params = {
    by: 'def.id',
    page: parameter?.page || 1,
    sort: 'desc',
    limit: parameter?.limit || 10,
    show_all: '1',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/voucher/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data == null ? [] : response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

//LIST SELECT VOUCHER
export const getSelectVoucher = createAsyncThunk('voucher/select', async () => {
  const params = {
    by: 'def.id',
    sort: 'asc',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/voucher/select?`, config)
  return response.data.data
})

//SEARCH VOUCHER
export const getSearchVoucher = createAsyncThunk('voucher/search', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    sort: parameter.sort,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(
    `${URL_API}/v1/api/voucher/?by=def.id&sort=desc&search=` + parameter.search,
    config,
  )
  // get 2 data
  const responseData = {
    data: response.data.data == null ? [] : response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

// DETAIL
export const getVoucherDetail = createAsyncThunk('voucher/detail', async (params) => {
  const response = await axios.get(`${URL_API}/v1/api/voucher/id/` + params.id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data
})

//DELETE
export const deleteVoucher = createAsyncThunk('voucher/delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/voucher/delete/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error')
    })

  return response.data.data.id
})

//ADD
export const addVoucher = createAsyncThunk('voucher/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/voucher/add`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error add')
    })

  return response.data.data
})

//ADD PHOTO
export const addVoucherImage = createAsyncThunk('voucher-image/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/voucher/photo`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error add')
    })

  return response.data.data
})

//UPDATE
export const updateVoucher = createAsyncThunk('voucher/update', async ({ formdata }) => {
  const response = await axios
    .put(`${URL_API}/v1/api/voucher/edit/` + formdata.id, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })

    .catch((err) => {
      console.log(err.response.data.messages.err_msg[0])
    })
  //console.log(response, 'res update')
  // get 2 data
  const responseData = {
    data: response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

const voucherEntity = createEntityAdapter({
  selectId: (voucher) => voucher.id,
})

const voucherSlice = createSlice({
  name: 'voucher',
  initialState: voucherEntity.getInitialState(),
  extraReducers: {
    [getVoucherList.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      voucherEntity.setAll(state, action.payload)
    },
    [getSearchVoucher.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      voucherEntity.setAll(state, action.payload)
    },
    [getVoucherDetail.fulfilled]: (state, action) => {
      voucherEntity.setOne(state, action.payload)
    },
    [deleteVoucher.fulfilled]: (state, action) => {
      voucherEntity.removeOne(state, action.payload)
    },
    [addVoucher.fulfilled]: (state, action) => {
      voucherEntity.addOne(state, action.payload)
    },
    [addVoucherImage.fulfilled]: (state, action) => {
      voucherEntity.addOne(state, action.payload)
    },
    [updateVoucher.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        voucherEntity.updateOne(state, {
          id: action.payload.id,
          updates: action.payload,
        })
      }
    },
    [getSelectVoucher.fulfilled]: (state, action) => {
      voucherEntity.setAll(state, action.payload)
    },
  },
})

export const voucherSelector = voucherEntity.getSelectors((state) => state.voucher)
export const selectVoucher = (state) => state.voucher
export default voucherSlice.reducer
