import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//GET DATA ALL ITEM
export const getProductLine = createAsyncThunk('products/Select', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(
    `${URL_API}/v1/api/web/item-uom-line/?by=def.created_date`,
    config,
  )
  const responseData = {
    data: response.data.data.list_item_uom_line,
    meta: response.data.meta,
  }

  return responseData
})

//SEARCH ITEM
export const getSearchItem = createAsyncThunk('products/searchProduct', async (parameter) => {
  const params = {
    search: parameter.search,
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(
    `${URL_API}/v1/api/web/item-uom-line/?by=def.created_date`,
    config,
  )
  const responseData = {
    data: response.data.data.list_item_uom_line,
    meta: response.data.meta,
  }

  return responseData
})

const productLineEntity = createEntityAdapter({
  selectId: (productLine) => productLine.item_uom_line_id,
})

const productLineSlice = createSlice({
  name: 'productLine',
  initialState: productLineEntity.getInitialState(),
  extraReducers: {
    [getProductLine.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      productLineEntity.setAll(state, action.payload)
    },

    [getSearchItem.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      productLineEntity.setAll(state, action.payload)
    },
  },
})

export const productLineSelector = productLineEntity.getSelectors((state) => state.productLine)
export const selectProductLine = (state) => state.product
export default productLineSlice.reducer
