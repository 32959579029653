import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//LIST SELECT SALESMAN TYPE
export const getSalesmanType = createAsyncThunk('salesman-type/get', async () => {
  const params = {
    by: 'def.id',
    sort: 'asc',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/salesman_type/select?`, config)
  // console.log('branch option', response.data.data.list_branch)
  return response.data.data
})

const salesmanTypeEntity = createEntityAdapter({
  selectId: (salesmanType) => (salesmanType ? salesmanType.id : null),
})

const salesmanTypeSlice = createSlice({
  name: 'salesmanType',
  initialState: salesmanTypeEntity.getInitialState(),
  extraReducers: {
    [getSalesmanType.fulfilled]: (state, action) => {
      salesmanTypeEntity.setAll(state, action.payload)
    },
  },
})

export const salesmanTypeSelector = salesmanTypeEntity.getSelectors((state) => state.salesmanType)
export default salesmanTypeSlice.reducer
