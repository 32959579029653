import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'
import moment from 'moment'

export const getOrders = createAsyncThunk('order/getOrders', async (parameter) => {
  const today = new Date()
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

  let by
  if (parameter.by === 'transaction_date' || parameter.by === 'transaction_time') {
    by = decodeURIComponent('def.transaction_date%2Bdef.transaction_time')
  } else if (parameter.by === 'customer_name') {
    by = 'cus.customer_name'
  } else {
    by = parameter.by
  }

  const params = {
    // by: 'def.created_date',
    // by: decodeURIComponent('def.transaction_date%2Bdef.transaction_time'),
    // sort: 'desc',
    by: by,
    sort: parameter.sort || 'desc',
    page: parameter?.page || 1,
    admin_user_id: localStorage.getItem('user_id'),
    limit: parameter?.limit || 10,
    start_date: moment(`${firstDayOfMonth}`).format('YYYY-MM-DD'),
    end_date: moment(`${lastDayOfMonth}`).format('YYYY-MM-DD'),
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/customerorder/?`, config)
  const responseData = {
    data: response.data.data.list_customer_order,
    meta: response.data.meta,
  }
  return responseData
})

export const getSearchOrders = createAsyncThunk('order/getOrders', async (parameter) => {
  let by
  if (parameter.by === 'transaction_date' || parameter.by === 'transaction_time') {
    by = decodeURIComponent('def.transaction_date%2Bdef.transaction_time')
  } else if (parameter.by === 'cus.customer_name') {
    by = 'cus.customer_name'
  } else if (parameter.by === 'cus.customer_code') {
    by = 'cus.customer_code'
  } else if (parameter.by === 'def.net_amount') {
    by = 'def.net_amount'
  } else if (parameter.by === 'b._name') {
    by = 'b._name'
  } else {
    by = 'def.created_date'
  }

  const params = {
    by: by,
    sort: parameter.sort || 'desc',
    page: parameter?.page || 1,
    admin_user_id: localStorage.getItem('user_id'),
    limit: parameter?.limit || 10,
    search: parameter.search,
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    status: parameter.status,
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/customerorder/?`, config)
  const responseData = {
    data:
      response.data.data.list_customer_order == null ? [] : response.data.data.list_customer_order,
    meta: response.data.meta,
  }
  return responseData
})

//pesanan detail
export const getOrderDetail = createAsyncThunk('order/OrderDetail', async (params) => {
  const response = await axios.get(
    `${URL_API}/v1/api/web/customerorder/id/` + params.id_customer_order_header,
  )

  return response.data.data.list_customer_order
})

//by nomer pesanan
export const getOrderDetailDoc = createAsyncThunk('order/OrderDetailDoc', async (params) => {
  const response = await axios.get(
    `${URL_API}/v1/api/web/customerorder/document_no/` + params.document_no,
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    },
  )

  return response.data.data
})

const orderEntity = createEntityAdapter({
  selectId: (order) => order.id_customer_order_header,
})

const orderSlice = createSlice({
  name: 'order',
  initialState: orderEntity.getInitialState(),
  extraReducers: {
    [getOrders.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      orderEntity.setAll(state, action.payload)
    },
    [getOrderDetail.fulfilled]: (state, action) => {
      orderEntity.setAll(state, action.payload)
    },
    [getSearchOrders.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      orderEntity.setAll(state, action.payload)
    },

    [getOrderDetailDoc.fulfilled]: (state, action) => {
      // Assuming the API returns an object with invoices array
      console.log('action payload:', action.payload) // Log the action payload

      if (action.payload && Array.isArray(action.payload)) {
        orderEntity.setAll(state, action.payload)
      } else if (action.payload) {
        orderEntity.setAll(state, [action.payload]) // Handle single invoice object
      }
    },
  },
})

export const orderSelector = orderEntity.getSelectors((state) => state.order)
export const selectOrder = (state) => state.order
export default orderSlice.reducer
