import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getTotalAktivasiCustomer = createAsyncThunk(
  'dashboardBranchDetail/customer',
  async (parameter) => {
    const params = {
      branch_id: parameter.branch_id,
    }
    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    const response = await axios.get(`${URL_API}/v1/api/web/dashboard/select/customer?`, config)

    return response.data.data
  },
)

//Dashboard Filter
export const filterDateCustomer = createAsyncThunk(
  'dashboardBranchDetail/Filter-date',
  async (parameter) => {
    const params = {
      branch_id: parameter.branch_id,
      start_date: parameter.start_date,
      end_date: parameter.end_date,
    }
    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    const response = await axios.get(`${URL_API}/v1/api/web/dashboard/select/customer?`, config)
    return response.data.data
  },
)

const dashboardBranchDetailEntity = createEntityAdapter({
  selectId: (dashboardBranchDetail) => dashboardBranchDetail.customer_id,
})

const dashboardBranchDetailSlice = createSlice({
  name: 'dashboardBranchDetail',
  initialState: dashboardBranchDetailEntity.getInitialState(),
  extraReducers: {
    [getTotalAktivasiCustomer.fulfilled]: (state, action) => {
      dashboardBranchDetailEntity.setAll(state, action.payload)
    },
    [filterDateCustomer.fulfilled]: (state, action) => {
      dashboardBranchDetailEntity.setAll(state, action.payload)
    },
  },
})

export const dashboardBranchDetailSelector = dashboardBranchDetailEntity.getSelectors(
  (state) => state.dashboardBranchDetail,
)
export const selectDashboardBranchDetail = (state) => state.dashboardBranchDetail
export default dashboardBranchDetailSlice.reducer
