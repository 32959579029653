import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//GET LIST MASTER POIN MAX
export const getPoinMax = createAsyncThunk('poin-max/list', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    by: 'def.id',
    sort: 'desc',
    show_all: '',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/point_max_customer/?`, config)
  console.log('reposnse:', response)
  // get 2 data
  const responseData = {
    data: response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

//GET LIST MASTER POIN MAX Filter
export const getPoinMaxFilter = createAsyncThunk('poin-max/list', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    by: 'def.id',
    sort: 'desc',
    show_all: parameter.show_all,
    search: parameter.search,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/point_max_customer/?`, config)
  console.log('reposnse:', response)
  // get 2 data
  const responseData = {
    data: response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

//SELCT ALL POIN MAX
export const getPoinMaxSelect = createAsyncThunk('poin-max/list-select', async (parameter) => {
  const params = {
    by: 'def.id',
    sort: 'desc',
    // show_all: parameter.show_all,
    // search: parameter.search,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/point_max_customer/select?`, config)

  return response.data.data
})

//SELCT ALL POIN MAX FILTER
export const getPoinMaxSelectFilter = createAsyncThunk(
  'poin-max/list-select-filter',
  async (parameter) => {
    const params = {
      by: 'def.id',
      sort: 'desc',
      show_all: parameter.show_all,
      search: parameter.search,
    }
    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    const response = await axios.get(`${URL_API}/v1/api/point_max_customer/select?`, config)

    return response.data.data
  },
)

//ADD POIN MAX
export const addPoinMax = createAsyncThunk('poin-max/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/point_max_customer/`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data
})

//UPDATE POIN MAX
export const updatePoinMax = createAsyncThunk('poin-max/update', async ({ formdata }) => {
  // console.log('form data', formdata)
  const response = await axios
    .put(`${URL_API}/v1/api/point_max_customer/` + formdata.id, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data
})

//DELETE POIN MAX
export const deletePoinMax = createAsyncThunk('poin-max/Delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/point_max_customer/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data.id
})

const poinMaxEntity = createEntityAdapter({
  selectId: (poinMax) => poinMax.id,
})

const poinMaxSlice = createSlice({
  name: 'poinMax',
  initialState: poinMaxEntity.getInitialState(),
  extraReducers: {
    [getPoinMax.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      poinMaxEntity.setAll(state, action.payload)
    },
    [getPoinMaxFilter.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      poinMaxEntity.setAll(state, action.payload)
    },

    [addPoinMax.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        poinMaxEntity.addOne(state, action.payload)
      }
    },
    [updatePoinMax.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        poinMaxEntity.updateOne(state, {
          id: action.payload.id,
          updates: action.payload,
        })
      }
    },
    [deletePoinMax.fulfilled]: (state, action) => {
      poinMaxEntity.removeOne(state, action.payload)
    },
    [getPoinMaxSelect.fulfilled]: (state, action) => {
      poinMaxEntity.setAll(state, action.payload)
    },
    [getPoinMaxSelectFilter.fulfilled]: (state, action) => {
      poinMaxEntity.setAll(state, action.payload)
    },
  },
})

export const poinMaxSelector = poinMaxEntity.getSelectors((state) => state.poinMax)
export default poinMaxSlice.reducer
