import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//LIST GET SALESMAN
export const getSalesman = createAsyncThunk('salesman/list', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    by: 'def.salesman_name',
    admin_user_id: localStorage.getItem('user_id'),
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/salesman/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_salesman,
    meta: response.data.meta,
  }
  return responseData
})

//LIST GET SEARCH SALESMAN
export const getSearch = createAsyncThunk('salesman/list', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    by: 'def.salesman_name',
    search: parameter.search,
    branch_id: parameter.branch_id,
    admin_user_id: localStorage.getItem('user_id'),
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/salesman/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_salesman == null ? [] : response.data.data.list_salesman,
    meta: response.data.meta,
  }
  return responseData
})

// DETAIL
export const getSalesmanDetail = createAsyncThunk('salesman/detail', async (params) => {
  const response = await axios.get(`${URL_API}/v1/api/web/salesman/id/` + params.salesman_id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data.salesman
})

//ADD
export const addSalesman = createAsyncThunk('salesman/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/web/salesman`, formdata, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data.list_salesman
})

//UPDATE
export const updateSalesman = createAsyncThunk('salesman/update', async ({ formdata }) => {
  const response = await axios
    .put(`${URL_API}/v1/api/web/salesman/id/` + formdata.salesman_id, formdata, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })
  // get 2 data
  const responseData = {
    data: response.data.data.list_salesman,
    meta: response.data.meta,
  }
  return responseData
})

//GET REPORT SALESMAN

export const getReportSalesman = createAsyncThunk(
  'report/report-master-salesman',
  async (parameter) => {
    const params = {
      // admin_user_id: localStorage.getItem('user_id'),
      branch_id: parameter.branch_id,
      by: 'def.id',
    }
    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    const response = await axios
      .get(`${URL_API}/v1/api/web/salesman/select?`, config)

      .catch((err) => {
        // console.log(err.response)
      })

    return response.data.data.list_salesman == null ? [] : response.data.data.list_salesman
  },
)

const salesmanEntity = createEntityAdapter({
  selectId: (salesman) => salesman.salesman_id,
})

const salesmanSlice = createSlice({
  name: 'salesman',
  initialState: salesmanEntity.getInitialState(),
  extraReducers: {
    [getSalesman.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      salesmanEntity.setAll(state, action.payload)
    },
    [getSearch.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      salesmanEntity.setAll(state, action.payload)
    },
    [getSalesmanDetail.fulfilled]: (state, action) => {
      salesmanEntity.setOne(state, action.payload)
    },
    [addSalesman.fulfilled]: (state, action) => {
      salesmanEntity.addOne(state, action.payload)
    },
    [updateSalesman.fulfilled]: (state, action) => {
      salesmanEntity.updateOne(state, {
        salesman_id: action.payload.salesman_id,
        updates: action.payload,
      })
    },
    [getReportSalesman.fulfilled]: (state, action) => {
      salesmanEntity.setAll(state, action.payload)
    },
  },
})

export const salesmanSelector = salesmanEntity.getSelectors((state) => state.salesman)
export const selectSalesman = (state) => state.salesman
export default salesmanSlice.reducer
