import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//GET PROMO BONUS
export const getPromoBonus = createAsyncThunk('promoBonus/Get', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    promo_line_id: parameter.promo_line_id,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(
    `${URL_API}/v1/api/web/promo_bonus_item_line/select?by=pil.id`,
    config,
  )
  let resultData = response.data.data.list_promo_bonus_item_line
  if (resultData == null) {
    resultData = []
  }
  return resultData
})

//ADD PROMO BONUS
export const addPromoBonus = createAsyncThunk('bonus/addBonus', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/web/promo_bonus_item_line/add`, formdata, {
      headers: {
        // Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data.list_promo_bonus_item_line
})

//DELETE PROMO ITEM LINE
export const deletePromoBonus = createAsyncThunk('promoLine/Delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/web/promo_bonus_item_line/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data.id
})

const promoBonusEntity = createEntityAdapter({
  selectId: (promoBonus) => promoBonus.id,
})

const promoBonusSlice = createSlice({
  name: 'promoBonus',
  initialState: promoBonusEntity.getInitialState(),
  extraReducers: {
    [getPromoBonus.fulfilled]: (state, action) => {
      promoBonusEntity.setAll(state, action.payload)
    },

    [addPromoBonus.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        promoBonusEntity.addOne(state, action.payload)
      }
    },
    [deletePromoBonus.fulfilled]: (state, action) => {
      promoBonusEntity.removeOne(state, action.payload)
    },
  },
})

export const promoBonusSelector = promoBonusEntity.getSelectors((state) => state.promoBonus)
export default promoBonusSlice.reducer
