import { Cookies } from 'react-cookie'

const cookies = new Cookies()

export const setCookies = (name, value) => {
  cookies.set(name, value, { path: '/' })
}

export const getCookies = (name) => cookies.get(name)

export const removeCookies = (name) => {
  cookies.remove(name, { path: '/' })
}

export const isAuth = () => !!getCookies('token')

export const userLogin = () => (!!getCookies('userLogin') ? getCookies('userLogin') : null)
