import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//LIST GET ROLE
export const getRoleList = createAsyncThunk('role/list', async (parameter) => {
  const params = {
    by: 'def.id',
    page: parameter?.page || 1,
    sort: 'desc',
    limit: parameter?.limit || 10,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/user_role/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_role_group,
    meta: response.data.meta,
  }
  return responseData
})

//ADD ROLE
export const addRole = createAsyncThunk('role/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/web/user_role`, formdata, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data.list_role_group
})

//UPDATE ROLE
export const updateRole = createAsyncThunk('role/update', async ({ formdata }) => {
  const response = await axios
    .put(`${URL_API}/v1/api/web/user_role/id/` + formdata.id_role, formdata, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  // get 2 data
  const responseData = {
    data: response.data.data.list_role_group,
    meta: response.data.meta,
  }
  return responseData
})

//DELETE ROLE
export const deleteRole = createAsyncThunk('role/Delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/web/user_role/id/` + data.id_role, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data.id
})

// DETAIL
export const getRoleDetail = createAsyncThunk('role/detail', async (params) => {
  const response = await axios.get(`${URL_API}/v1/api/web/user_role/id/` + params.id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data
})

const roleEntity = createEntityAdapter({
  selectId: (role) => role.id,
})

const roleSlice = createSlice({
  name: 'role',
  initialState: roleEntity.getInitialState(),
  extraReducers: {
    [getRoleList.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      roleEntity.setAll(state, action.payload)
    },
    [addRole.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        roleEntity.addOne(state, action.payload)
      }
    },
    [updateRole.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        roleEntity.updateOne(state, {
          id: action.payload.id_role,
          updates: action.payload,
        })
      }
    },
    [getRoleDetail.fulfilled]: (state, action) => {
      roleEntity.setOne(state, action.payload)
    },
    [deleteRole.fulfilled]: (state, action) => {
      roleEntity.removeOne(state, action.payload)
    },
  },
})

export const roleSelector = roleEntity.getSelectors((state) => state.role)
export const selectRole = (state) => state.role
export default roleSlice.reducer
