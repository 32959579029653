import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getAreaDash = createAsyncThunk('dashboard/Area Detail', async (parameter) => {
  const params = {
    group_id: parameter.group_id,
    start_date: parameter.start_date,
    end_date: parameter.end_date,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios
    .get(`${URL_API}/v1/api/web/dashboard/group?`, config)

    .catch((err) => {
      //console.log(err.response)
    })
  //console.log('respon data:', response.data.data)
  return response.data.data == null ? [] : response.data.data
})

//Dashboard Filter
export const filterArea = createAsyncThunk('dashboardGroup/Search', async (parameter) => {
  const params = {
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    group_id: parameter.group_id,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/dashboard/group?`, config)
  //console.log('filter:', response.data.data)
  return response.data.data == null ? [] : response.data.data
})

const dashboardAreaEntity = createEntityAdapter({
  selectId: (dashboardArea) => dashboardArea.region_id_detail,
})

const dashboardAreaSlice = createSlice({
  name: 'dashboardArea',
  initialState: dashboardAreaEntity.getInitialState(),
  extraReducers: {
    [getAreaDash.fulfilled]: (state, action) => {
      dashboardAreaEntity.setAll(state, action.payload)
    },
    [filterArea.fulfilled]: (state, action) => {
      dashboardAreaEntity.setAll(state, action.payload)
    },
  },
})

export const dashboardAreaSelector = dashboardAreaEntity.getSelectors(
  (state) => state.dashboardArea,
)
export default dashboardAreaSlice.reducer
