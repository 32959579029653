import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//LIST GET BROADCAST
export const getBroadcast = createAsyncThunk('broadcasr/list', async (parameter) => {
  const params = {
    by: 'b.id',
    page: parameter?.page || 1,
    sort: 'desc',
    limit: parameter?.limit || 10,
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/broadcast/?`, config)
  console.log('response:', response.data.data)
  // get 2 data
  const responseData = {
    data: response.data.data == null ? [] : response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

//LIST BROADCAST SELECT
export const geBroadcastSelect = createAsyncThunk('broadcast/list--select', async () => {
  const params = {
    by: 'b.id',
    sort: 'desc',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/broadcast/select?`, config)

  return response.data.data
})

//SEARCH BROADCAST
export const getSearchBroadcast = createAsyncThunk('broadcast/search', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    sort: 'desc',
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(
    `${URL_API}/v1/api/web/broadcast/?by=b.id&sort=asc&search=` + parameter.search,
    config,
  )
  // get 2 data
  const responseData = {
    data: response.data.data == null ? [] : response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

// DETAIL
export const getBroadcastDetail = createAsyncThunk('broadcast/detail', async (params) => {
  const response = await axios
    .get(`${URL_API}/v1/api/broadcast/id/` + params.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error detail')
    })

  return response.data.data
})

//ADD
export const addBroadcast = createAsyncThunk('broadcast/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/broadcast/add`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error add')
    })

  return response.data.data
})

//DELETE
export const deleteBroadcast = createAsyncThunk('broadcast/delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/broadcast/delete/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error')
    })

  return response.data.data.id
})

//EXECUTE
export const executeBroadcast = createAsyncThunk('broadcast/execute', async (data) => {
  const response = await axios
    .post(`${URL_API}/v1/api/broadcast/execute/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error')
    })

  return response.data.data.id
})

//UPDATE
export const updateBroadcast = createAsyncThunk('broadcast/update', async ({ formdata }) => {
  // let formObject = Object.fromEntries(formdata.entries())
  // let formdatavalue = formObject.form_data
  // let formData = JSON.parse(formdatavalue)

  const response = await axios
    .put(`${URL_API}/v1/api/broadcast/edit/` + formdata.id, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })

    .catch((err) => {
      console.log(err.response.data.messages.err_msg[0])
    })
  //console.log(response, 'res update')
  // get 2 data
  const responseData = {
    data: response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

const broadcastEntity = createEntityAdapter({
  selectId: (broadcast) => broadcast.id,
})

const broadcastSlice = createSlice({
  name: 'broadcast',
  initialState: broadcastEntity.getInitialState(),
  extraReducers: {
    [getBroadcast.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      broadcastEntity.setAll(state, action.payload)
    },
    [geBroadcastSelect.fulfilled]: (state, action) => {
      broadcastEntity.setAll(state, action.payload)
    },

    [getSearchBroadcast.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      broadcastEntity.setAll(state, action.payload)
    },
    [getBroadcastDetail.fulfilled]: (state, action) => {
      broadcastEntity.setOne(state, action.payload)
    },
    [addBroadcast.fulfilled]: (state, action) => {
      broadcastEntity.addOne(state, action.payload)
      // if (action.payload && action.payload.id) {
      //   broadcastEntity.addOne(state, action.payload)
      // }
    },
    [executeBroadcast.fulfilled]: (state, action) => {
      broadcastEntity.addOne(state, action.payload)
    },
    [deleteBroadcast.fulfilled]: (state, action) => {
      broadcastEntity.removeOne(state, action.payload)
    },
    [updateBroadcast.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        broadcastEntity.updateOne(state, {
          id: action.payload.id,
          updates: action.payload,
        })
      }

      // broadcastEntity.updateOne(state, {
      //   id: action.payload.id,
      //   updates: action.payload,
      // })
    },
  },
})

export const broadcastSelector = broadcastEntity.getSelectors((state) => state.broadcast)
export const selectBroadcast = (state) => state.broadcast
export default broadcastSlice.reducer
