import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getOmzetRegion = createAsyncThunk('omzet/getOmzetRegion', async () => {
  const response = await axios.get(`${URL_API}/v1/api/web/dashboard/omzet`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data
})

export const searchOmzet = createAsyncThunk('omzet/Search', async (parameter) => {
  const params = {
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    item_category_ids: parameter.item_category_ids,
    item_ids: parameter.item_ids,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/dashboard/omzet?`, config)

  return response.data.data
})

const omzetEntity = createEntityAdapter({
  selectId: (omzet) => omzet.region_group_id,
})

const omzetSlice = createSlice({
  name: 'omzet',
  initialState: omzetEntity.getInitialState(),
  extraReducers: {
    [getOmzetRegion.fulfilled]: (state, action) => {
      omzetEntity.setAll(state, action.payload)
    },
    [searchOmzet.fulfilled]: (state, action) => {
      omzetEntity.setAll(state, action.payload)
    },
  },
})

export const omzetSelector = omzetEntity.getSelectors((state) => state.omzet)
export default omzetSlice.reducer
