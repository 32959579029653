import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getOrderDetail = createAsyncThunk('order/OrderDetail', async (params) => {
  const response = await axios.get(
    `${URL_API}/v1/api/web/customerorder/id/` + params.id_customer_order_header,
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    },
  )

  return response.data.data
})

const orderlistEntity = createEntityAdapter({
  selectId: (orderlist) => orderlist.id_customer_order_header,
})

const orderlistSlice = createSlice({
  name: 'orderlist',
  initialState: orderlistEntity.getInitialState(),
  extraReducers: (builder) => {
    builder.addCase(getOrderDetail.fulfilled, (state, action) => {
      orderlistEntity.upsertOne(state, action.payload)
    })
  },
})

export const { selectById } = orderlistEntity.getSelectors((state) => state.orderlist)

//export const orderlistSelector = orderlistEntity.getSelectors((state) => state.orderlist)
export default orderlistSlice.reducer
