import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//LIST GET USER
export const getUserList = createAsyncThunk('user/list', async (parameter) => {
  const params = {
    by: 'def.id',
    page: parameter?.page || 1,
    sort: 'desc',
    limit: parameter?.limit || 10,
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/_user/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_role_group == null ? [] : response.data.data.list_role_group,
    meta: response.data.meta,
  }
  return responseData
})

//SEARCH USER
export const getSearchUser = createAsyncThunk('user/search', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(
    `${URL_API}/v1/api/web/_user/?by=def.id&sort=desc&search=` + parameter.search,
    config,
  )
  // get 2 data
  const responseData = {
    data: response.data.data.list_role_group == null ? [] : response.data.data.list_role_group,
    meta: response.data.meta,
  }
  return responseData
})

// DETAIL
export const getUserDetail = createAsyncThunk('user/detail', async (params) => {
  const response = await axios.get(`${URL_API}/v1/api/web/_user/id/` + params.id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data
})

//ADD
export const addUser = createAsyncThunk('user/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/web/_user`, formdata, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error add')
    })

  return response.data.data.list_role_group
})

//DELETE
export const deleteUser = createAsyncThunk('user/delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/web/_user/id/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data.id
})

//UPDATE
export const updateUser = createAsyncThunk('user/update', async ({ formdata }) => {
  const response = await axios
    .put(`${URL_API}/v1/api/web/_user/id/` + formdata.id, formdata, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  // get 2 data
  const responseData = {
    data: response.data.data.list_role_group,
    meta: response.data.meta,
  }
  return responseData
})

const userEntity = createEntityAdapter({
  selectId: (user) => user.id,
})

const userSlice = createSlice({
  name: 'user',
  initialState: userEntity.getInitialState(),
  extraReducers: {
    [getUserList.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      userEntity.setAll(state, action.payload)
    },
    [getSearchUser.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      userEntity.setAll(state, action.payload)
    },
    [getUserDetail.fulfilled]: (state, action) => {
      userEntity.setOne(state, action.payload)
    },
    [addUser.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        userEntity.addOne(state, action.payload)
      }
    },
    [deleteUser.fulfilled]: (state, action) => {
      userEntity.removeOne(state, action.payload)
    },
    [updateUser.fulfilled]: (state, action) => {
      userEntity.updateOne(state, {
        id: action.payload.id,
        updates: action.payload,
      })
    },
  },
})

export const userSelector = userEntity.getSelectors((state) => state.user)
export const selectUser = (state) => state.user
export default userSlice.reducer
