import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//LIST SELECT BRANCH
export const getBranch = createAsyncThunk('branch/get branch', async () => {
  const params = {
    by: 'def.id',
    user_id: localStorage.getItem('user_id'),
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/branch/select?`, config)
  return response.data.data.list_branch
})

//GET MASTER BRANCH
export const getMasterBranch = createAsyncThunk('branch/list', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    by: 'def.id',
    user_id: localStorage.getItem('user_id'),
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/branch/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_branch,
    meta: response.data.meta,
  }
  return responseData
})

//GET SEARVH BRANCH
export const getSearchBranch = createAsyncThunk('branch/list', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    by: 'def.id',
    user_id: localStorage.getItem('user_id'),
    search: parameter.search,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/branch/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_branch,
    meta: response.data.meta,
  }
  return responseData
})

//UPDATE BRANCH
export const updateBranch = createAsyncThunk('branch/update', async ({ formdata }) => {
  const response = await axios
    .put(`${URL_API}/v1/api/web/branch/id/` + formdata.branch_id, formdata, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })
  // get 2 data
  const responseData = {
    data: response.data.data.list_branch,
    meta: response.data.meta,
  }
  return responseData
})

// DETAIL
export const getBranchDetail = createAsyncThunk('branch/detail', async (params) => {
  const response = await axios.get(`${URL_API}/v1/api/web/branch/id/` + params.branch_id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
  //console.log('resp data: ', response.data.data)
  return response.data.data
})

const branchEntity = createEntityAdapter({
  selectId: (branch) => (branch ? branch.branch_id : null),
})

const branchSlice = createSlice({
  name: 'branch',
  initialState: branchEntity.getInitialState(),
  extraReducers: {
    [getBranch.fulfilled]: (state, action) => {
      branchEntity.setAll(state, action.payload)
    },
    [getMasterBranch.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      branchEntity.setAll(state, action.payload)
    },
    [getSearchBranch.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      branchEntity.setAll(state, action.payload)
    },
    [updateBranch.fulfilled]: (state, action) => {
      if (action.payload && action.payload.branch_id) {
        branchEntity.updateOne(state, {
          branch_id: action.payload.branch_id,
          updates: action.payload,
        })
      }
    },
    [getBranchDetail.fulfilled]: (state, action) => {
      branchEntity.setOne(state, action.payload)
    },
  },
})

export const branchSelector = branchEntity.getSelectors((state) => state.branch)
export default branchSlice.reducer
