import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import store from './store'
import { Provider } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { AuthContextProvider } from './views/chat/context/AuthContext'
import { ChatContextProvider } from './views/chat/context/ChatContext'
import 'leaflet/dist/leaflet.css'

createRoot(document.getElementById('root')).render(
  <AuthContextProvider>
    <ChatContextProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ChatContextProvider>
  </AuthContextProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
