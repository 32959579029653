import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//OMZET YEARLY PER MONTH
export const getOmzetPerMonth = createAsyncThunk('omzetPerMonth/perMonth', async (parameter) => {
  const params = {
    user_id: localStorage.getItem('user_id'),
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/dashboard/omzet/graph`, config)

  return response.data.data
})

export const searchOmzetPerMonth = createAsyncThunk(
  'omzetPerMonth/SearchPerMonth',
  async (parameter) => {
    const params = {
      year: parameter.year,
      region_id: parameter.region_id,
      region_group_id: parameter.region_group_id,
      branch_id: parameter.branch_id,
      item_category_ids: parameter.item_category_ids,
      item_ids: parameter.item_ids,
      user_id: localStorage.getItem('user_id'),
    }
    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    const response = await axios.get(`${URL_API}/v1/api/web/dashboard/omzet/graph?`, config)

    return response.data.data
  },
)

const omzetPerMonthEntity = createEntityAdapter({
  selectId: (omzetPerMonth) => omzetPerMonth.id,
})

const omzetPerMonthSlice = createSlice({
  name: 'omzetPerMonth',
  initialState: omzetPerMonthEntity.getInitialState(),
  extraReducers: {
    [getOmzetPerMonth.fulfilled]: (state, action) => {
      omzetPerMonthEntity.setAll(state, action.payload)
    },
    [searchOmzetPerMonth.fulfilled]: (state, action) => {
      omzetPerMonthEntity.setAll(state, action.payload)
    },
  },
})

export const omzetPerMonthSelector = omzetPerMonthEntity.getSelectors(
  (state) => state.omzetPerMonth,
)
export default omzetPerMonthSlice.reducer
