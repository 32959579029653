import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//GET LIST MASTER POIN MEKAR
export const getPoinMekar = createAsyncThunk('poin-mekar/list', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    by: 'def.id',
    sort: 'desc',
    point_type: '1',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/point/?`, config)

  // get 2 data
  const responseData = {
    data: response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

//GET LIST MASTER POIN MEKAR FILTER
export const getPoinMekarFilter = createAsyncThunk('poin-mekar/list-filter', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    by: 'def.id',
    sort: 'desc',
    point_type: '1',
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    search: parameter.search,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/point/?`, config)

  // get 2 data
  const responseData = {
    data: response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

//LIST SELECT POIN MEKAR
export const getPoinMekarSelect = createAsyncThunk('poin-mekar/list-select', async (parameter) => {
  const params = {
    by: 'def.id',
    sort: 'desc',
    point_type: '1',
    start_date: parameter.start_date,
    end_date: parameter.end_date,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/point/select?`, config)

  return response.data.data
})

//ADD POIN MEKAR
export const addPoinMekar = createAsyncThunk('poin-mekar/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/point/`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error add')
    })

  return response.data.data
})

//ADD POIN MEKAR INJECT [yg dipake]
export const addPoinMekarInject = createAsyncThunk(
  'poin-mekar/add-inject',
  async ({ formdata }) => {
    const response = await axios
      .post(`${URL_API}/v1/api/point/inject/`, formdata, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .catch((err) => {
        console.log(err, 'error add')
      })

    return response.data.data
  },
)

//UPDATE POIN MEKAR
export const updatePoinMekar = createAsyncThunk('poin-mekar/update', async ({ formdata }) => {
  // console.log('form data', formdata)
  const response = await axios
    .put(`${URL_API}/v1/api/point/` + formdata.id, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data
})

//DELETE POIN MEKAR
export const deletePoinMekar = createAsyncThunk('poin-mekar/Delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/point/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data.id
})

// DETAIL
export const getDetailPoinMekar = createAsyncThunk('poin-mekar/detail', async (params) => {
  const response = await axios.get(`${URL_API}/v1/api/point/id/` + params.id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data
})

//POIN DEDUCTION (PENGURANGAN POIN)

//GET LIST MASTER POIN DEDUCTION
export const getPoinDeduction = createAsyncThunk('poin-mekar/poin-deduction', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    by: 'def.id',
    sort: 'desc',
    point_type: '5',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/point/?`, config)

  // get 2 data
  const responseData = {
    data: response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

//GET LIST MASTER POIN DEDUCTION FILTER
export const getPoinDeductionFilter = createAsyncThunk(
  'poin-mekar/list-filter-poin-deduction',
  async (parameter) => {
    const params = {
      page: parameter?.page || 1,
      limit: parameter?.limit || 10,
      by: 'def.id',
      sort: 'desc',
      point_type: '5',
      start_date: parameter.start_date,
      end_date: parameter.end_date,
      search: parameter.search,
    }
    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    const response = await axios.get(`${URL_API}/v1/api/point/?`, config)

    // get 2 data
    const responseData = {
      data: response.data.data,
      meta: response.data.meta,
    }
    return responseData
  },
)

const poinMekarEntity = createEntityAdapter({
  selectId: (poinMekar) => poinMekar.id,
})

const poinMekarSlice = createSlice({
  name: 'poinMekar',
  initialState: poinMekarEntity.getInitialState(),
  extraReducers: {
    [getPoinMekar.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      poinMekarEntity.setAll(state, action.payload)
    },
    [addPoinMekar.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        poinMekarEntity.addOne(state, action.payload)
      }
    },
    [addPoinMekarInject.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        poinMekarEntity.addOne(state, action.payload)
      }
    },
    [getPoinMekarFilter.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      poinMekarEntity.setAll(state, action.payload)
    },
    [getPoinMekarSelect.fulfilled]: (state, action) => {
      poinMekarEntity.setAll(state, action.payload)
    },
    [updatePoinMekar.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        poinMekarEntity.updateOne(state, {
          id: action.payload.id,
          updates: action.payload,
        })
      }
    },
    [deletePoinMekar.fulfilled]: (state, action) => {
      poinMekarEntity.removeOne(state, action.payload)
    },
    [getDetailPoinMekar.fulfilled]: (state, action) => {
      poinMekarEntity.setOne(state, action.payload)
    },
    [getPoinDeduction.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      poinMekarEntity.setAll(state, action.payload)
    },
    [getPoinDeductionFilter.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      poinMekarEntity.setAll(state, action.payload)
    },
  },
})

export const poinMekarSelector = poinMekarEntity.getSelectors((state) => state.poinMekar)
export default poinMekarSlice.reducer
