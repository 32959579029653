import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getRegion = createAsyncThunk('region/getRegion', async () => {
  const response = await axios.get(`${URL_API}/v1/api/web/dashboard`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
  //console.log(response.data.data)
  return response.data.data
})

//Dashboard Filter
export const searchRegion = createAsyncThunk('region/Search', async (parameter) => {
  const params = {
    start_date: parameter.start_date,
    end_date: parameter.end_date,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/dashboard?`, config)
  //console.log('filter:', response.data.data)
  return response.data.data
})

const dashboardEntity = createEntityAdapter({
  selectId: (dashboard) => dashboard.region_id,
})

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: dashboardEntity.getInitialState(),
  extraReducers: {
    [getRegion.fulfilled]: (state, action) => {
      dashboardEntity.setAll(state, action.payload)
    },
    [searchRegion.fulfilled]: (state, action) => {
      dashboardEntity.setAll(state, action.payload)
    },
  },
})

export const dashboardSelector = dashboardEntity.getSelectors((state) => state.dashboard)
export default dashboardSlice.reducer
