import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getAreaOmzet = createAsyncThunk('omzet/Omzet Area', async (parameter) => {
  const params = {
    group_id: parameter.group_id,
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    item_category_ids: parameter.item_category_ids,
    item_ids: parameter.item_ids,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/dashboard/omzet/group?`, config)

  return response.data.data.area == null ? [] : response.data.data.area
})

export const searchAreaOmzet = createAsyncThunk('omzet/Omzet Area', async (parameter) => {
  const params = {
    group_id: parameter.group_id,
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    item_category_ids: parameter.item_category_ids,
    item_ids: parameter.item_ids,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/dashboard/omzet/group?`, config)

  return response.data.data.area == null ? [] : response.data.data.area
})

const omzetAreaEntity = createEntityAdapter({
  selectId: (omzetArea) => omzetArea.region_id,
})

const omzetAreaSlice = createSlice({
  name: 'omzetArea',
  initialState: omzetAreaEntity.getInitialState(),
  extraReducers: {
    [getAreaOmzet.fulfilled]: (state, action) => {
      omzetAreaEntity.setAll(state, action.payload)
    },
    [searchAreaOmzet.fulfilled]: (state, action) => {
      omzetAreaEntity.setAll(state, action.payload)
    },
  },
})

export const omzetAreaSelector = omzetAreaEntity.getSelectors((state) => state.omzetArea)
export default omzetAreaSlice.reducer
