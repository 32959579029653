import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getCategorys = createAsyncThunk('products/getCategorys', async () => {
  const response = await axios.get(
    `${URL_API}/v1/api/apps/item_category/select?by=def.created_date`,
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    },
  )

  return response.data.data.list_category
})

//GET BY ID
export const getCatByID = createAsyncThunk('products/getcatbyid', async (parameter) => {
  const response = await axios.get(`${URL_API}/v1/api/web/item/category_id/` + parameter.id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data
})

//SEARCH ITEM
export const getSearchCat = createAsyncThunk('category/search', async (parameter) => {
  const response = await axios.get(
    `${URL_API}/v1/api/apps/item_category/select?by=def.created_date&search=` + parameter.search,
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    },
  )
  return response.data.data.list_category
})

const categoryEntity = createEntityAdapter({
  selectId: (category) => category.id,
})

const categorySlice = createSlice({
  name: 'category',
  initialState: categoryEntity.getInitialState(),
  extraReducers: {
    [getCategorys.fulfilled]: (state, action) => {
      categoryEntity.setAll(state, action.payload)
    },
    [getSearchCat.fulfilled]: (state, action) => {
      categoryEntity.setAll(state, action.payload)
    },
    [getCatByID.fulfilled]: (state, action) => {
      categoryEntity.setAll(state, action.payload)
    },
  },
})

export const categorySelector = categoryEntity.getSelectors((state) => state.category)
export default categorySlice.reducer
