import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getCustomerOmzet = createAsyncThunk('omzet/omzet-customer', async (parameter) => {
  const params = {
    customer_id: parameter.customer_id,
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    item_category_ids: parameter.item_category_ids,
    item_ids: parameter.item_ids,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/dashboard/omzet/customer?`, config)

  return response.data.data.customers == null ? [] : response.data.data.customers
})

export const searchCustomerOmzet = createAsyncThunk(
  'omzet/search-omzet-customer',
  async (parameter) => {
    const params = {
      customer_id: parameter.customer_id,
      start_date: parameter.start_date,
      end_date: parameter.end_date,
      item_category_ids: parameter.item_category_ids,
      item_ids: parameter.item_ids,
    }
    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }

    const response = await axios.get(`${URL_API}/v1/api/web/dashboard/omzet/customer?`, config)

    return response.data.data.customers == null ? [] : response.data.data.customers
  },
)

const omzetCustomerEntity = createEntityAdapter({
  selectId: (omzetCustomer) => omzetCustomer.item_id,
})

const omzetCustomerSlice = createSlice({
  name: 'omzetCustomer',
  initialState: omzetCustomerEntity.getInitialState(),
  extraReducers: {
    [getCustomerOmzet.fulfilled]: (state, action) => {
      omzetCustomerEntity.setAll(state, action.payload)
    },
    [searchCustomerOmzet.fulfilled]: (state, action) => {
      omzetCustomerEntity.setAll(state, action.payload)
    },
  },
})

export const omzetCustomerSelector = omzetCustomerEntity.getSelectors(
  (state) => state.omzetCustomer,
)
export default omzetCustomerSlice.reducer
