import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

// GET PROMO LINE
export const getPromoLine = createAsyncThunk('promoLine/GetPromoLine', async (parameter) => {
  const response = await axios.get(
    `${URL_API}/v1/api/apps/promo_line/?by=pl.id&promo_id=` + parameter.promo_id,
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    },
  )
  let resultData = response.data.data.list_promo_line
  if (resultData == null) {
    resultData = []
  }

  return resultData
})

//ADD PROMO LINE
export const addPromoLine = createAsyncThunk('promoLine/addPromoLine', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/apps/promo_line/add`, formdata, {
      headers: {
        // Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data.list_promo_line
})

//UPDATE
export const updatePromoLine = createAsyncThunk('promoLine/update', async ({ formdata }) => {
  const response = await axios
    .put(`${URL_API}/v1/api/apps/promo_line/update/` + formdata.id, formdata, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  // get 2 data
  const responseData = {
    data: response.data.data.list_promo_line,
    meta: response.data.meta,
  }
  return responseData
})

//DELETE PROMO
export const deletePromoLine = createAsyncThunk('promo/Delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/apps/promo_line/delete/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data.id
})

const promoLineEntity = createEntityAdapter({
  selectId: (promoLine) => promoLine.id,
})

const promoLineSlice = createSlice({
  name: 'promoLine',
  initialState: promoLineEntity.getInitialState(),
  extraReducers: {
    [getPromoLine.fulfilled]: (state, action) => {
      promoLineEntity.setAll(state, action.payload)
    },

    [addPromoLine.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        promoLineEntity.addOne(state, action.payload)
      }
    },

    [updatePromoLine.fulfilled]: (state, action) => {
      promoLineEntity.updateOne(state, {
        id: action.payload.id,
        updates: action.payload,
      })
    },
    [deletePromoLine.fulfilled]: (state, action) => {
      promoLineEntity.removeOne(state, action.payload)
    },
  },
})

export const promoLineSelector = promoLineEntity.getSelectors((state) => state.promoLine)
export const selectPromoLine = (state) => state.promoLine
export default promoLineSlice.reducer
