import React, { createContext, useContext,  useReducer} from "react";
import { AuthContext } from "./AuthContext";
import PropTypes from 'prop-types';


export const ChatContext = createContext()

export const ChatContextProvider = ({children}) => {
    const {currentUser} = useContext(AuthContext);
    const INITIAL_STATE = {
        // SENDER + PENERIMA
        chatId: "null",
        // SENDER
        user: {},
    };

    const chatReducer = (state, action) => {
        switch(action.type) {
            case "CHANGE_USER":
                return {
                    user: action.payload["userToUid"],
                    //chatId:  currentUser.uid > action.payload ? currentUser.uid + action.payload : action.payload + currentUser.uid
                    chatId: action.payload["chatId"]
                };
                default:
                    return state;
        }
    };


    const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);

    return (
        //it's mean component {children} can reach current user. <Auth>. can reach currentUser
        <ChatContext.Provider value={{data:state,  dispatch}}>
            {children}
        </ChatContext.Provider>
    );
    
};

ChatContextProvider.propTypes = {
    children:  PropTypes.object
  };
  