import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//GET POIN REDEEMED LIST
export const getPoinRedeem = createAsyncThunk('poin-redeem/list', async (parameter) => {
  const params = {
    by: 'def.id',
    page: parameter?.page || 1,
    sort: 'desc',
    limit: parameter?.limit || 10,
    // now: parameter.now,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/coupon/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data == null ? [] : response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

// GET ALL
export const getPoinRedeemAll = createAsyncThunk('poin-redeem/list-poin-all', async () => {
  const params = {
    by: 'def.id',
    sort: 'asc',
    // now: parameter.now,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/coupon/select?`, config)

  return response.data.data
})

//ADD
export const addPoinRedeem = createAsyncThunk('poin-redeem/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/coupon/`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error add')
    })

  return response.data.data
})

//ADD PHOTO
export const addPoinRedeemImage = createAsyncThunk(
  'poin-redeem-image/add',
  async ({ formdata }) => {
    const response = await axios
      .post(`${URL_API}/v1/api/coupon/photo`, formdata, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .catch((err) => {
        console.log(err, 'error add')
      })

    return response.data.data
  },
)

// DETAIL
export const getPoinRedeemDetail = createAsyncThunk('poin-redeem/detail', async (params) => {
  const response = await axios.get(`${URL_API}/v1/api/coupon/id/` + params.id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data
})

//DELETE
export const deletePoinRedeem = createAsyncThunk('poin-redeem/delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/coupon/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error')
    })

  return response.data.data.id
})

//UPDATE POIN
export const updatePoinRedeem = createAsyncThunk('poin-redeem/update', async ({ formdata }) => {
  const response = await axios
    .put(`${URL_API}/v1/api/coupon/` + formdata.id, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data
})

const poinRedeemEntity = createEntityAdapter({
  selectId: (poinRedeem) => poinRedeem.id,
})

const poinRedeemSlice = createSlice({
  name: 'poinRedeem',
  initialState: poinRedeemEntity.getInitialState(),
  extraReducers: {
    [getPoinRedeem.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      poinRedeemEntity.setAll(state, action.payload)
    },
    [getPoinRedeemAll.fulfilled]: (state, action) => {
      poinRedeemEntity.setAll(state, action.payload)
    },

    [addPoinRedeem.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        poinRedeemEntity.addOne(state, action.payload)
      }
    },
    [addPoinRedeemImage.fulfilled]: (state, action) => {
      poinRedeemEntity.addOne(state, action.payload)
    },
    [updatePoinRedeem.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        poinRedeemEntity.updateOne(state, {
          id: action.payload.id,
          updates: action.payload,
        })
      }
    },
    [getPoinRedeemDetail.fulfilled]: (state, action) => {
      poinRedeemEntity.setOne(state, action.payload)
    },
    [deletePoinRedeem.fulfilled]: (state, action) => {
      poinRedeemEntity.removeOne(state, action.payload)
    },
  },
})

export const poinRedeemSelector = poinRedeemEntity.getSelectors((state) => state.poinRedeem)
export const selectPoinRedeem = (state) => state.poinRedeem
export default poinRedeemSlice.reducer
