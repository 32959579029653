import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//by nomer invoice
export const getInvoiceDetailDoc = createAsyncThunk('invoice/InvoiceDetailDoc', async (params) => {
  // Replace '/' with '&' in document_no
  const sanitizedDocumentNo = params.no_invoice.replace(/\//g, '&')

  const response = await axios.get(
    `${URL_API}/v1/api/apps/sales_invoice/document_no/` + sanitizedDocumentNo,   {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    },
  )

 
  return response.data
})

const invoiceDocEntity = createEntityAdapter({
  selectId: (invoiceDoc) => invoiceDoc.invoice_id,
})

const invoiceDocSlice = createSlice({
  name: 'invoiceDoc',
  initialState: invoiceDocEntity.getInitialState(),
  extraReducers: {
    [getInvoiceDetailDoc.fulfilled]: (state, action) => {
      // Assuming the API returns an object with invoices array
      console.log('action payload:', action.payload) // Log the action payload

      if (action.payload && Array.isArray(action.payload)) {
        invoiceDocEntity.setAll(state, action.payload)
      } else if (action.payload) {
        invoiceDocEntity.setAll(state, [action.payload]) // Handle single invoice object
      }
    },
  },
})

// const invoiceDocSlice = createSlice({
//   name: 'invoiceDoc',
//   initialState: invoiceDocEntity.getInitialState(),
//   extraReducers: {
//     [getInvoiceDetailDoc.fulfilled]: (state, action) => {
//       invoiceDocEntity.setAll(state, action.payload)
//     },
//   },
// })

export const invoiceDocSelector = invoiceDocEntity.getSelectors((state) => state.invoiceDoc)
export default invoiceDocSlice.reducer
