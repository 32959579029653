import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//GET LIST COUPON
export const getCouponList = createAsyncThunk('coupon/list', async (parameter) => {
  const params = {
    by: 'def.id',
    page: parameter?.page || 1,
    sort: 'asc',
    limit: parameter?.limit || 10,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/lottary/paginate?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data == null ? [] : response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

//GET LIST COUPON FILTER
export const getCouponFilter = createAsyncThunk('coupon/list-filter', async (parameter) => {
  const params = {
    by: 'def.id',
    page: parameter?.page || 1,
    sort: 'asc',
    limit: parameter?.limit || 10,
    search: parameter.search,
    branch_id: parameter.branch_id,
    region_id: parameter.region_id,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/lottary/paginate?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data == null ? [] : response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

// GET ALL REPORT
export const getCouponReport = createAsyncThunk('coupon/report-coupon', async (parameter) => {
  const params = {
    by: 'def.id',
    sort: 'asc',
    branch_id: parameter.branch_id,
    region_id: parameter.region_id,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/lottary/reportdata?`, config)

  return response.data.data
})

// DETAIL
export const getCouponDetail = createAsyncThunk('coupon/detail', async (params) => {
  const response = await axios.get(`${URL_API}/v1/api/lottary/id/` + params.id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data
})

//ADD
export const addGenerateCoupon = createAsyncThunk('coupon/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/lottary/Import`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error add')
    })

  return response.data.data
})

//DELETE COUPON
export const deleteAllCoupon = createAsyncThunk('coupon/Delete', async () => {
  const response = await axios
    .delete(`${URL_API}/v1/api/lottary/all`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data
})

const couponEntity = createEntityAdapter({
  selectId: (coupon) => coupon.id,
})

const couponSlice = createSlice({
  name: 'coupon',
  initialState: couponEntity.getInitialState(),
  extraReducers: {
    [getCouponList.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      couponEntity.setAll(state, action.payload)
    },
    [getCouponFilter.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      couponEntity.setAll(state, action.payload)
    },

    [addGenerateCoupon.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        couponEntity.addOne(state, action.payload)
      }
    },
    [getCouponReport.fulfilled]: (state, action) => {
      couponEntity.setAll(state, action.payload)
    },
    [getCouponDetail.fulfilled]: (state, action) => {
      couponEntity.setOne(state, action.payload)
    },
    [deleteAllCoupon.fulfilled]: (state, action) => {
      couponEntity.removeAll(state, action.payload)
    },
  },
})

export const couponSelector = couponEntity.getSelectors((state) => state.coupon)
export const selectCoupon = (state) => state.coupon
export default couponSlice.reducer
