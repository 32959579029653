import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getSPSummary = createAsyncThunk('dashboard/SP Detail', async (parameter) => {
  const params = {
    group_id: parameter.group_id,
    region_id: parameter.region_id,
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    customer_level_id: parameter.customer_level_id,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/dashboard/detail?`, config)
  return response.data.data == null ? [] : response.data.data
})

//Dashboard Filter
export const filterSP = createAsyncThunk('SP/Search', async (parameter) => {
  const params = {
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    group_id: parameter.group_id,
    region_id: parameter.region_id,
    customer_level_id: parameter.customer_level_id,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/dashboard/detail?`, config)

  return response.data.data == null ? [] : response.data.data
})

const dashboardSpEntity = createEntityAdapter({
  selectId: (dashboardSp) => dashboardSp.branch_id_detail,
})

const dashboardSpSlice = createSlice({
  name: 'dashboardSp',
  initialState: dashboardSpEntity.getInitialState(),
  extraReducers: {
    [getSPSummary.fulfilled]: (state, action) => {
      dashboardSpEntity.setAll(state, action.payload)
    },
    [filterSP.fulfilled]: (state, action) => {
      dashboardSpEntity.setAll(state, action.payload)
    },
  },
})

export const dashboardSpSelector = dashboardSpEntity.getSelectors((state) => state.dashboardSp)
export default dashboardSpSlice.reducer
