import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getTotal = createAsyncThunk('dashboard/cust Detail', async (parameter) => {
  const params = {
    by: 'def.customer_name',
    user_id: localStorage.getItem('user_id'),
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/dashboard/select/branch?`, config)

  return response.data.data == null ? [] : response.data.data
})

//Dashboard Filter
export const filterDate = createAsyncThunk('dashboardCS/Filter date', async (parameter) => {
  const params = {
    by: 'def.customer_name',
    user_id: localStorage.getItem('user_id'),
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    customer_level_id: parameter.customer_level_id,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/dashboard/select/branch?`, config)
  return response.data.data == null ? [] : response.data.data
})

const dashboardBranchEntity = createEntityAdapter({
  selectId: (dashboardBranch) => dashboardBranch.branch_id,
})

const dashboardBranchSlice = createSlice({
  name: 'dashboardBranch',
  initialState: dashboardBranchEntity.getInitialState(),
  extraReducers: {
    [getTotal.fulfilled]: (state, action) => {
      dashboardBranchEntity.setAll(state, action.payload)
    },
    [filterDate.fulfilled]: (state, action) => {
      dashboardBranchEntity.setAll(state, action.payload)
    },
  },
})

export const dashboardBranchSelector = dashboardBranchEntity.getSelectors(
  (state) => state.dashboardBranch,
)
export const selectdashboardBranch = (state) => state.dashboardBranch
export default dashboardBranchSlice.reducer
