import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

// LIST TABLE TOKO Max Poin Custom
export const getCustomersMaxPoin = createAsyncThunk('customers/getCustomers', async (parameter) => {
  const params = {
    by: 'c.customer_name',
    sort: 'desc',
    admin_user_id: localStorage.getItem('user_id'),
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    monthly_max_point: '1',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/customer/?by=c.created_date`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_customer == null ? [] : response.data.data.list_customer,
    meta: response.data.meta,
  }

  return responseData
})

// LIST TABLE TOKO OPTION
export const getCustomersOption = createAsyncThunk('customers/getCustomers', async (parameter) => {
  const params = {
    by: 'c.id',
    admin_user_id: localStorage.getItem('user_id'),
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    branch_id: parameter.branch_id,
    show_in_app: parameter.show_in_app,
    monthly_max_point: '1',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/customer/?by=c.created_date`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_customer == null ? [] : response.data.data.list_customer,
    meta: response.data.meta,
  }
  //console.log('resp data: ', response.data.data.list_customer)
  return responseData
})

//SEARCH BY NAME MAX POIN
export const getSearchMaxPoin = createAsyncThunk('customers/getSearch', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    // by: 'c.customer_name',
    admin_user_id: localStorage.getItem('user_id'),
    search: parameter.search,
    branch_id: parameter.branch_id,
    phone_number: parameter.phone_number,
    show_in_app: parameter.show_in_app,
    sort: parameter.sort,
    by: `c.${parameter.by || 'id'}`,
    customer_type_id: parameter.customer_type_id,
    salesman_type_id: parameter.salesman_type_id,
    is_data_complete: parameter.is_data_complete,
    active: parameter.active,
    admin_validate: parameter.admin_validate,
    monthly_max_point: '1',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/customer/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_customer == null ? [] : response.data.data.list_customer,
    meta: response.data.meta,
  }
  //console.log('search toko:', response)
  return responseData
})

const customerMaxPoinEntity = createEntityAdapter({
  selectId: (customerMaxPoin) => customerMaxPoin.customer_id,
})

const customerMaxPoinSlice = createSlice({
  name: 'customerMaxPoin',
  initialState: customerMaxPoinEntity.getInitialState(),
  extraReducers: {
    [getCustomersMaxPoin.fulfilled]: (state, action) => {
      //get 2 data
      state.data = action.payload.data
      state.meta = action.payload.meta
      customerMaxPoinEntity.setAll(state, action.payload)
    },
    [getCustomersOption.fulfilled]: (state, action) => {
      //get 2 data
      state.data = action.payload.data
      state.meta = action.payload.meta
      customerMaxPoinEntity.setAll(state, action.payload)
    },

    [getSearchMaxPoin.fulfilled]: (state, action) => {
      //get 2 data
      state.data = action.payload.data
      state.meta = action.payload.meta
      customerMaxPoinEntity.setAll(state, action.payload)
    },
  },
})

export const customerMaxPoinSelector = customerMaxPoinEntity.getSelectors(
  (state) => state.customerMaxPoin,
)
export const selectCustomerMaxPoin = (state) => state.customerMaxPoin
export default customerMaxPoinSlice.reducer
