import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'


//GET ALL
export const getCustomerType = createAsyncThunk('customerType/getCSType', async () => {
  const params = { by: 'def.id' }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/customertype/select?`, config)
  //console.log(response.data.data.list_customertype)
  return response.data.data.list_customertype
})


const customerTypeEntity = createEntityAdapter({
  selectId: (customerType) => customerType.customer_type_id,
})

const customerTypeSlice = createSlice({
  name: 'customerType',
  initialState: customerTypeEntity.getInitialState(),
  extraReducers: {
    [getCustomerType.fulfilled]: (state, action) => {
      customerTypeEntity.setAll(state, action.payload)
    },
  },
})

export const customerTypeSelector = customerTypeEntity.getSelectors((state) => state.customerType)
export default customerTypeSlice.reducer
