import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//LIST GET PARTNER
export const getpartnerList = createAsyncThunk('partnet/list', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/partner/?by=def._name`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_partner,
    meta: response.data.meta,
  }
  return responseData
})

//ADD
export const addPartner = createAsyncThunk('partner/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/web/partner`, formdata, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data.list_partner
})

// DETAIL
export const getPartnerDetail = createAsyncThunk('partner/detail', async (params) => {
  const response = await axios.get(`${URL_API}/v1/api/web/partner/id/` + params.partner_id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data.partner
})

//UPDATE
export const updatePartner = createAsyncThunk('partner/update', async ({ formdata }) => {
  const response = await axios
    .put(`${URL_API}/v1/api/web/partner/id/` + formdata.partner_id, formdata, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })
  // get 2 data
  const responseData = {
    data: response.data.data.list_partner,
    meta: response.data.meta,
  }
  return responseData
})

const partnerEntity = createEntityAdapter({
  selectId: (partner) => partner.partner_id,
})

const partnerSlice = createSlice({
  name: 'partner',
  initialState: partnerEntity.getInitialState(),
  extraReducers: {
    [getpartnerList.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      partnerEntity.setAll(state, action.payload)
    },
    [addPartner.fulfilled]: (state, action) => {
      partnerEntity.addOne(state, action.payload)
    },
    [getPartnerDetail.fulfilled]: (state, action) => {
      partnerEntity.setOne(state, action.payload)
    },
    [updatePartner.fulfilled]: (state, action) => {
      partnerEntity.updateOne(state, {
        partner_id: action.payload.partner_id,
        updates: action.payload,
      })
    },
  },
})

export const partnerSelector = partnerEntity.getSelectors((state) => state.partner)
export const selectPartner = (state) => state.partner
export default partnerSlice.reducer
