import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getReportCustomer = createAsyncThunk(
  'report/report-master-toko',
  async (parameter) => {
    const params = {
      admin_user_id: localStorage.getItem('user_id'),
      region_group_id: parameter.region_group_id,
      region_id: parameter.region_id,
      branch_area: parameter.branch_area,
      customer_type_id: parameter.customer_type_id,
      branch_ids: parameter.branch_ids,
      customer_level_id: parameter.customer_level_id,
      customer_profile_status: parameter.customer_profile_status,
      show_in_app: parameter.show_in_app,
    }

    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      mode: 'no-cors',
    }

    const response = await axios
      .get(`${URL_API}/v1/api/web/customer/report?`, config)

      .catch((err) => {
        // console.log(err.response)
      })

    return response.data.data == null ? [] : response.data.data
  },
)

const reportCustEntity = createEntityAdapter({
  selectId: (reportCust) => reportCust.customer_id,
})

const reportCustSlice = createSlice({
  name: 'reportCust',
  initialState: reportCustEntity.getInitialState(),
  extraReducers: {
    [getReportCustomer.fulfilled]: (state, action) => {
      reportCustEntity.setAll(state, action.payload)
    },
  },
})

export const reportCustSelector = reportCustEntity.getSelectors((state) => state.reportCust)
export default reportCustSlice.reducer
