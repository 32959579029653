import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getReportTracking = createAsyncThunk(
  'report/report-tracking-invoice',
  async (parameter) => {
    const params = {
      user_id: localStorage.getItem('user_id'),
      start_date: parameter.start_date,
      end_date: parameter.end_date,
      region_group_id: parameter.region_group_id,
      region_id: parameter.region_id,
      branch_area: parameter.branch_area,
      // customer_type_id: parameter.customer_type_id,
      branch_id: parameter.branch_id,
      customer_level_id: parameter.customer_level_id,
    }
    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
 
    const response = await axios
      .get(`${URL_API}/v1/api/web/dashboard/tracking/invoice?`, config)

      .catch((err) => {
        // console.log(err.response)
      })
  
    return response.data.data == null ? [] : response.data.data
  },
)

const reportTrackingInvoiceEntity = createEntityAdapter({
  selectId: (reportTrackingInvoice) => reportTrackingInvoice.invoice_id,
})

const reportTrackingInvoiceSlice = createSlice({
  name: 'reportTrackingInvoice',
  initialState: reportTrackingInvoiceEntity.getInitialState(),
  extraReducers: {
    [getReportTracking.fulfilled]: (state, action) => {
      reportTrackingInvoiceEntity.setAll(state, action.payload)
    },
  },
})

export const reportTrackingInvoiceSelector = reportTrackingInvoiceEntity.getSelectors(
  (state) => state.reportTrackingInvoice,
)
export default reportTrackingInvoiceSlice.reducer
