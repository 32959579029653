import React from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { createContext, useEffect, useState } from 'react'
import { auth } from '../../../firebase'
import PropTypes from 'prop-types'

export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
      // console.log(user);
    })

    return () => {
      unsub()
    }
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      // Lakukan validasi token disini atau set langsung jika hanya memeriksa keberadaan
      setCurrentUser({}) // Set user objek berdasarkan token
    }
    setIsLoading(false)
  }, [])

  return (
    //it's mean component {children} can reach current user. <Auth>. can reach currentUser
    <AuthContext.Provider value={{ currentUser, isLoading }}>{children}</AuthContext.Provider>
  )
}

AuthContextProvider.propTypes = {
  children: PropTypes.object,
}
