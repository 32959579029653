import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getReportLog = createAsyncThunk('report/report-log', async (parameter) => {
  const params = {
    user_id: localStorage.getItem('user_id'),
    by: 'def.id',
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    region_group_id: parameter.region_group_id,
    region_id: parameter.region_id,
    branch_id: parameter.branch_id,
    customer_level_id: parameter.customer_level_id,
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios
    .get(`${URL_API}/v1/api/customer_log/?`, config)

    .catch((err) => {
      // console.log(err.response)
    })

  return response.data.data == null ? [] : response.data.data
})

const reportLogEntity = createEntityAdapter({
  selectId: (reportLog) => reportLog.id,
})

const reportLogSlice = createSlice({
  name: 'reportLog',
  initialState: reportLogEntity.getInitialState(),
  extraReducers: {
    [getReportLog.fulfilled]: (state, action) => {
      reportLogEntity.setAll(state, action.payload)
    },
  },
})

export const reportLogSelector = reportLogEntity.getSelectors((state) => state.reportLog)
export default reportLogSlice.reducer
