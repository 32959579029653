import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//LIST BRANCH
export const getArea = createAsyncThunk('area/get area', async () => {
  const params = {
    by: 'def._name',
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/region_area/select?`, config)
  // console.log('area option', response.data.data.list_region_area)
  return response.data.data.list_region_area
})

const areaEntity = createEntityAdapter({
  selectId: (area) => area.id,
})

const areaSlice = createSlice({
  name: 'area',
  initialState: areaEntity.getInitialState(),
  extraReducers: {
    [getArea.fulfilled]: (state, action) => {
      areaEntity.setAll(state, action.payload)
    },
  },
})

export const areaSelector = areaEntity.getSelectors((state) => state.area)
export default areaSlice.reducer
