import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore";
import { getStorage} from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyCYusXyAocyGlU6I3NxEgViyckzC5DjIDE",
  authDomain: "nextbasis-onlinestore.firebaseapp.com",
  projectId: "nextbasis-onlinestore",
  storageBucket: "nextbasis-onlinestore.appspot.com",
  messagingSenderId: "258658333079",
  appId: "1:258658333079:web:ab00e166e658ddadee54d2",
  measurementId: "G-YYX2GP85ZC"
};



// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth()
export const storage = getStorage();
export const db = getFirestore();
