import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//GET PROMO ITEM LINE

export const getPromoItemLine = createAsyncThunk('promoItemLine/Get', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    promo_line_id: parameter.promo_line_id,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/promo_item_line/?by=pil.id`, config)
  const responseData = {
    data:
      response.data.data.list_promo_item_line == null
        ? []
        : response.data.data.list_promo_item_line,
    meta: response.data.meta,
  }
  return responseData
})

//ADD PROMO ITEM LINE
export const addPromoItemLine = createAsyncThunk('itemLine/addItemLine', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/web/promo_item_line/add`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data.list_promo_item_line
})

//DELETE PROMO ITEM LINE
export const deletePromoItemLine = createAsyncThunk('promoLine/Delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/web/promo_item_line/delete/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data.id
})

const promoItemLineEntity = createEntityAdapter({
  selectId: (promoItemLine) => promoItemLine.promo_line_id,
})

const promoItemLineSlice = createSlice({
  name: 'promoItemLine',
  initialState: promoItemLineEntity.getInitialState(),
  extraReducers: {
    [getPromoItemLine.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      promoItemLineEntity.setAll(state, action.payload)
    },

    [addPromoItemLine.fulfilled]: (state, action) => {
      if (action.payload && action.payload.promo_line_id) {
        promoItemLineEntity.addOne(state, action.payload)
      }
    },

    [deletePromoItemLine.fulfilled]: (state, action) => {
      promoItemLineEntity.removeOne(state, action.payload)
    },
  },
})

export const promoItemLineSelector = promoItemLineEntity.getSelectors(
  (state) => state.promoItemLine,
)
export const selectPromoItemLine = (state) => state.promoItemLine
export default promoItemLineSlice.reducer
