import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//GET LIST VOOUCHER Redeem
export const getVoucherRedeem = createAsyncThunk('voucher-redeem/list', async (parameter) => {
  const params = {
    by: 'def.id',
    page: parameter?.page || 1,
    sort: 'desc',
    limit: parameter?.limit || 10,
    show_all: '1',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/voucher_redeem/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data == null ? [] : response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

// GET ALL
export const getVoucherRedeemAll = createAsyncThunk('voucher-redeem/list-voucher', async () => {
  const params = {
    by: 'def.id',
    sort: 'desc',
    show_all: '1',
    // search: parameter.search,
    // voucher_id: parameter.voucher_id,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/voucher_redeem/select?`, config)

  return response.data.data
})

// GET ALL
export const getVoucherRedeemAllFilter = createAsyncThunk(
  'voucher-redeem/list-voucher-filter',
  async (parameter) => {
    const params = {
      by: 'def.id',
      sort: 'desc',
      show_all: '1',
      search: parameter.search,
      voucher_id: parameter.voucher_id,
    }
    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }

    const response = await axios.get(`${URL_API}/v1/api/voucher_redeem/select?`, config)
    console.log('resp:', response)
    return response.data.data
  },
)

//GET LIST VOUCHER REDEEM
export const getVoucherRedeemFilter = createAsyncThunk('voucher-redeem/list', async (parameter) => {
  const params = {
    by: 'def.id',
    page: parameter?.page || 1,
    sort: 'desc',
    limit: parameter?.limit || 10,
    show_all: '1',
    search: parameter.search,
    voucher_id: parameter.voucher_id,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/voucher_redeem/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data == null ? [] : response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

//ADD
export const addVoucherRedeem = createAsyncThunk('voucher-redeem/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/voucher_redeem/add_bulk`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error add')
    })

  return response.data.data
})

// DETAIL
export const getVoucherRedeemDetail = createAsyncThunk('voucher-redeem/detail', async (params) => {
  const response = await axios.get(`${URL_API}/v1/api/voucher_redeem/id/` + params.id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data
})

//DELETE
export const deleteVoucherRedeem = createAsyncThunk('voucher-redeem/delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/voucher_redeem/delete/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error')
    })

  return response.data.data.id
})

const voucherRedeemEntity = createEntityAdapter({
  selectId: (voucherRedeem) => voucherRedeem.id,
})

const voucherRedeemSlice = createSlice({
  name: 'voucherRedeem',
  initialState: voucherRedeemEntity.getInitialState(),
  extraReducers: {
    [getVoucherRedeem.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      voucherRedeemEntity.setAll(state, action.payload)
    },
    [getVoucherRedeemFilter.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      voucherRedeemEntity.setAll(state, action.payload)
    },
    [getVoucherRedeemAll.fulfilled]: (state, action) => {
      voucherRedeemEntity.setAll(state, action.payload)
    },
    [getVoucherRedeemAllFilter.fulfilled]: (state, action) => {
      voucherRedeemEntity.setAll(state, action.payload)
    },
    [addVoucherRedeem.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        voucherRedeemEntity.addOne(state, action.payload)
      }
    },
    [getVoucherRedeemDetail.fulfilled]: (state, action) => {
      voucherRedeemEntity.setOne(state, action.payload)
    },
    [deleteVoucherRedeem.fulfilled]: (state, action) => {
      voucherRedeemEntity.removeOne(state, action.payload)
    },
  },
})

export const voucherRedeemSelector = voucherRedeemEntity.getSelectors(
  (state) => state.voucherRedeem,
)
export const selectVoucherRedeem = (state) => state.voucherRedeem
export default voucherRedeemSlice.reducer
