import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//LIST GET ROLE GROUP
export const getRolegroup = createAsyncThunk('rolegroup/list', async (parameter) => {
  const params = {
    by: 'def.id',
    page: parameter?.page || 1,
    sort: 'desc',
    limit: parameter?.limit || 10,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/role_group/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_role_group,
    meta: response.data.meta,
  }
  return responseData
})

// DETAIL
export const getRolegroupDetail = createAsyncThunk('rolegroup/detail', async (params) => {
  const response = await axios.get(`${URL_API}/v1/api/web/role_group/id/` + params.id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data
})

//ADD
export const addRoleGroup = createAsyncThunk('rolegroup/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/web/role_group`, formdata, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data.list_role_group
})

//UPDATE
export const updateRolegroup = createAsyncThunk('rolegroup/update', async ({ formdata }) => {
  const response = await axios
    .put(`${URL_API}/v1/api/web/role_group/id/` + formdata.id, formdata, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  // get 2 data
  const responseData = {
    data: response.data.data.list_role_group,
    meta: response.data.meta,
  }
  return responseData
})

//DELETE ROLE
export const deleteRoleGroup = createAsyncThunk('rolegroup/Delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/web/role_group/id/` + data.id_role, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data.id
})

const rolegroupEntity = createEntityAdapter({
  selectId: (rolegroup) => rolegroup.id,
})

const rolegroupSlice = createSlice({
  name: 'rolegroup',
  initialState: rolegroupEntity.getInitialState(),
  extraReducers: {
    [getRolegroup.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      rolegroupEntity.setAll(state, action.payload)
    },
    [getRolegroupDetail.fulfilled]: (state, action) => {
      rolegroupEntity.setOne(state, action.payload)
    },
    [addRoleGroup.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        rolegroupEntity.addOne(state, action.payload)
      }
    },
    [updateRolegroup.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        rolegroupEntity.updateOne(state, {
          id: action.payload.id,
          updates: action.payload,
        })
      }
    },
    [deleteRoleGroup.fulfilled]: (state, action) => {
      rolegroupEntity.removeOne(state, action.payload)
    },
  },
})

export const rolegroupSelector = rolegroupEntity.getSelectors((state) => state.rolegroup)
export const selectRolegroup = (state) => state.rolegroup
export default rolegroupSlice.reducer
