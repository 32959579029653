import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'
import moment from 'moment'

//LIST GET INVOICE

export const getInvoiceList = createAsyncThunk('invoice/InvoiceList', async (parameter) => {
  const today = new Date()
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

  const params = {
    by: 'def.document_no',
    user_id: localStorage.getItem('user_id'),
    page: parameter?.page || 1,
    sort: 'desc',
    limit: parameter?.limit || 10,
    start_date: moment(`${firstDayOfMonth}`).format('YYYY-MM-DD'),
    end_date: moment(`${lastDayOfMonth}`).format('YYYY-MM-DD'),
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/apps/sales_invoice/?`, config)

  // get 2 data
  const responseData = {
    data: response.data.data.list_invoice == null ? [] : response.data.data.list_invoice,
    meta: response.data.meta,
  }
  return responseData
})

//Invoice Detail
//by id
export const getInvoiceDetail = createAsyncThunk('customers/InvoiceDetail', async (params) => {
  const response = await axios.get(`${URL_API}/v1/api/apps/sales_invoice/id/` + params.invoice_id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data
})

//Invoice Filter
export const searchInvoice = createAsyncThunk('invoice/Search', async (parameter) => {
  const params = {
    by: 'def.document_no',
    user_id: localStorage.getItem('user_id'),
    sort: 'desc',
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    search: parameter.search,
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/apps/sales_invoice/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_invoice == null ? [] : response.data.data.list_invoice,
    meta: response.data.meta,
  }

  return responseData
})

const invoiceEntity = createEntityAdapter({
  selectId: (invoice) => invoice.invoice_id,
})

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: invoiceEntity.getInitialState(),
  extraReducers: {
    [getInvoiceList.fulfilled]: (state, action) => {
      //get 2 data
      state.data = action.payload.data
      state.meta = action.payload.meta
      invoiceEntity.setAll(state, action.payload)
    },

    [getInvoiceDetail.fulfilled]: (state, action) => {
      invoiceEntity.setAll(state, action.payload)
    },

    [searchInvoice.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      invoiceEntity.setAll(state, action.payload)

      // if (action.payload && action.payload.invoice_id) {
      //   //get 2 data
      //   state.data = action.payload.data
      //   state.meta = action.payload.meta
      //   invoiceEntity.setAll(state, action.payload)
      // }
    },
  },
})

export const invoiceSelector = invoiceEntity.getSelectors((state) => state.invoice)
export default invoiceSlice.reducer
