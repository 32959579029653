import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//ADD PROMO BONUS
export const addCategory = createAsyncThunk('category/add category', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/web/promo_item_line/add/by/category`, formdata, {
      headers: {
        // Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data
})

const promoCategoryEntity = createEntityAdapter({
  selectId: (promoCategory) => promoCategory.id,
})

const promoCategorySlice = createSlice({
  name: 'promoCategory',
  initialState: promoCategoryEntity.getInitialState(),
  extraReducers: {
    [addCategory.fulfilled]: (state, action) => {
      promoCategoryEntity.addOne(state, action.payload)
    },
  },
})

export const promoCategorySelector = promoCategoryEntity.getSelectors(
  (state) => state.promoCategory,
)
export default promoCategorySlice.reducer
