import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//POIN BALANCE TOKO
export const getPoinTokoBalance = createAsyncThunk('poin-toko/list-select', async (params) => {
  console.log('API pointoko hit')
  const response = await axios.get(
    `${URL_API}/v1/api/point/balance/customer_id/` + params.customer_id,
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    },
  )

  return response.data.data
})

const poinTokoBalanceEntity = createEntityAdapter({
  selectId: (poinTokoBalance) => poinTokoBalance.customer_id,
})

const poinTokoBalanceSlice = createSlice({
  name: 'poinTokoBalance',
  initialState: poinTokoBalanceEntity.getInitialState(),
  extraReducers: {
    [getPoinTokoBalance.fulfilled]: (state, action) => {
      poinTokoBalanceEntity.setAll(state, action.payload)
    },
  },
})

export const poinTokoBalanceSelector = poinTokoBalanceEntity.getSelectors(
  (state) => state.poinTokoBalance,
)
export default poinTokoBalanceSlice.reducer
