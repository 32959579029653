import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

let emptyData = []

export const getDashCust = createAsyncThunk('dashboard/custDetail', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    by: 'def.customer_name',
    branch_id: parameter.branch_id,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(
    `${URL_API}/v1/api/web/dashboard/detail/total/registered-user?`,
    config,
  )
  const responseData = {
    data: response.data.data == null ? [] : response.data.data,
    meta: response.data.meta,
  }
  //console.log('Response :', responseData)
  return responseData
})

export const getDashCustAll = createAsyncThunk('dashboard/cust Detail', async (parameter) => {
  const params = {
    by: 'def.customer_name',
    branch_id: parameter.branch_id,
    start_date: parameter.start_date,
    end_date: parameter.end_date,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(
    `${URL_API}/v1/api/web/dashboard/detail/total/registered-user?`,
    config,
  )

  if (response.data.data == null) {
    return emptyData
  } else {
    emptyData = response.data.data
    return emptyData
  }

  // return response.data.data == null ? [] : response.data.data
})

//Dashboard Filter
export const filterDate = createAsyncThunk('dashboardCS/Filter date', async (parameter) => {
  const params = {
    by: 'def.customer_name',
    branch_id: parameter.branch_id,
    start_date: parameter.start_date,
    end_date: parameter.end_date,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(
    `${URL_API}/v1/api/web/dashboard/detail/total/registered-user?`,
    config,
  )
  return response.data.data == null ? [] : response.data.data
})

const dashboardCustEntity = createEntityAdapter({
  selectId: (dashboardCust) => dashboardCust.customer_id_detail,
})

const dashboardCustSlice = createSlice({
  name: 'dashboardCust',
  initialState: dashboardCustEntity.getInitialState(),
  extraReducers: {
    [getDashCust.pending]: (state) => {
      state.pending = true
    },
    [getDashCust.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      dashboardCustEntity.setAll(state, action.payload)
      state.pending = false
    },
    [getDashCust.rejected]: (state) => {
      state.pending = false
    },
    [getDashCustAll.fulfilled]: (state, action) => {
      dashboardCustEntity.setAll(state, action.payload)
    },
    [filterDate.fulfilled]: (state, action) => {
      dashboardCustEntity.setAll(state, action.payload)
    },
  },
})

export const dashboardCustSelector = dashboardCustEntity.getSelectors(
  (state) => state.dashboardCust,
)
export const selectDashboardCust = (state) => state.dashboardCust
export default dashboardCustSlice.reducer
