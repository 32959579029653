import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

// GET ALL
export const getListOldPrice = createAsyncThunk('oldprice/list', async () => {
  const params = {
    by: 'def.id',
    sort: 'asc',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/item_old_price/select?`, config)

  return response.data.data
})

//ADD
export const addOldPrice = createAsyncThunk('oldprice/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/item_old_price/add`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error add')
    })

  return response.data.data
})

const oldPriceEntity = createEntityAdapter({
  selectId: (oldPrice) => oldPrice.id,
})

const oldPriceSlice = createSlice({
  name: 'oldPrice',
  initialState: oldPriceEntity.getInitialState(),
  extraReducers: {
    [getListOldPrice.fulfilled]: (state, action) => {
      oldPriceEntity.setAll(state, action.payload)
    },
    [addOldPrice.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        oldPriceEntity.addOne(state, action.payload)
      }
    },
  },
})

export const oldPriceSelector = oldPriceEntity.getSelectors((state) => state.oldPrice)
export const selectOldPrice = (state) => state.oldPrice
export default oldPriceSlice.reducer
