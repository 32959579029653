import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'

//IMPORT TARGET TOKO
export const importTarget = createAsyncThunk('customer-target/import', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/customer/target/add_bulk`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error add')
    })

  return response.data.data
})

//TARGET QUARTER
export const getTargetQuarter = createAsyncThunk('customers-target/quarter', async (parameter) => {
  console.log('API target quarter hit')
  const params = {
    by: 'cus.customer_name',
    customer_id: parameter.customer_id,
    quarter: parameter.quarter,
    year: parameter.year,
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/customer/target/quarter?`, config)
  //console.log('resp data: ', response.data.data)
  return response.data.data
})

//TARGET SEMESTER
export const getTargetSemester = createAsyncThunk(
  'customers-target/semester',
  async (parameter) => {
    console.log('API target semester hit')
    const params = {
      by: 'cus.customer_name',
      customer_id: parameter.customer_id,
      semester: parameter.semester,
      year: parameter.year,
    }

    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }

    const response = await axios.get(`${URL_API}/v1/api/customer/target/semester?`, config)
    // console.log('resp data: ', response.data.data)
    return response.data.data
  },
)

//TARGET YEAR
export const getTargetYearly = createAsyncThunk('customers-target/yearly', async (parameter) => {
  console.log('API target yearly hit')
  const params = {
    by: 'cus.customer_name',
    customer_id: parameter.customer_id,
    year: parameter.year,
    sort: 'asc',
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/customer/target/year?`, config)
  // console.log('resp data: ', response.data.data)
  return response.data.data == null ? [] : response.data.data
})

const targetEntity = createEntityAdapter({
  selectId: (target) => target.customer_id,
})

const targetSlice = createSlice({
  name: 'target',
  initialState: targetEntity.getInitialState(),
  extraReducers: {
    [importTarget.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        targetEntity.addOne(state, action.payload)
      }
    },

    [getTargetQuarter.fulfilled]: (state, action) => {
      targetEntity.setAll(state, action.payload)
    },
    [getTargetSemester.fulfilled]: (state, action) => {
      targetEntity.setAll(state, action.payload)
    },
    [getTargetYearly.fulfilled]: (state, action) => {
      targetEntity.setAll(state, action.payload)
    },
  },
})

export const targetSelector = targetEntity.getSelectors((state) => state.target)
export const selectTarget = (state) => state.target
export default targetSlice.reducer
