import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getReport = createAsyncThunk('report/TotalAktivasiCustomer', async (parameter) => {
  const params = {
    branch_id: parameter.branch_id,
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    user_id: localStorage.getItem('user_id'),
    customer_level_id: parameter.customer_level_id,
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/dashboard/branch/select/report?`, config)
  return response.data.data
})

const reportAktivasiEntity = createEntityAdapter({
  selectId: (reportAktivasi) => reportAktivasi.customer_id_detail,
})

const reportAktivasiSlice = createSlice({
  name: 'reportAktivasi',
  initialState: reportAktivasiEntity.getInitialState(),
  extraReducers: {
    [getReport.fulfilled]: (state, action) => {
      reportAktivasiEntity.setAll(state, action.payload)
    },
  },
})

export const reportAktivasiSelector = reportAktivasiEntity.getSelectors(
  (state) => state.reportAktivasi,
)
export const selectreportAktivasi = (state) => state.reportAktivasi
export default reportAktivasiSlice.reducer
