import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//LIST SELECT COUPON
export const getPoinRedeemCoupon = createAsyncThunk(
  'poin-coupon/list-select',
  async (parameter) => {
    const params = {
      by: 'def.id',
      sort: 'desc',
      customer_id: parameter.customer_id,
      show_all: '1',
    }
    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    const response = await axios.get(`${URL_API}/v1/api/coupon_redeem/select?`, config)

    return response.data.data
  },
)

//REPORT
export const getReportRedeemPoin = createAsyncThunk(
  'poin-coupon/report-redeem-poin',
  async (parameter) => {
    const params = {
      start_date: parameter.start_date,
      end_date: parameter.end_date,
      region_group_id: parameter.region_group_id,
      region_id: parameter.region_id,
      branch_id: parameter.branch_id,
      by: 'def.id',
      sort: 'desc',
      show_all: '1',
      coupon_status: parameter.coupon_status,
    }
    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }

    const response = await axios
      .get(`${URL_API}/v1/api/coupon_redeem/report?`, config)

      .catch((err) => {
        // console.log(err.response)
      })

    return response.data.data == null ? [] : response.data.data
  },
)

//REPORT REDEEMED ONLY
export const getReportRedeemedOnly = createAsyncThunk(
  'poin-coupon/report-redeem-poin-sudah-redeem',
  async (parameter) => {
    const params = {
      start_date: parameter.start_date,
      end_date: parameter.end_date,
      region_group_id: parameter.region_group_id,
      region_id: parameter.region_id,
      branch_id: parameter.branch_id,
      by: 'def.id',
      sort: 'desc',
      show_all: '1',
    }
    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }

    const response = await axios
      .get(`${URL_API}/v1/api/coupon_redeem/report/redeemed?`, config)

      .catch((err) => {
        // console.log(err.response)
      })

    return response.data.data == null ? [] : response.data.data
  },
)

//REVERT - MENGEMBALIKAN VOUCHER YG SUDAH DI REDEEM
export const revertPoinRedeem = createAsyncThunk('poin-coupon/revert', async (document_no) => {
  const response = await axios
    .put(
      `${URL_API}/v1/api/coupon_redeem/revert/${document_no}`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      },
    )
    .catch((err) => {
      console.log(err, 'error add')
    })

  return response.data.data
})

const poinCouponEntity = createEntityAdapter({
  selectId: (poinCoupon) => poinCoupon.id,
})

const poinCouponSlice = createSlice({
  name: 'poinCoupon',
  initialState: poinCouponEntity.getInitialState(),
  extraReducers: {
    [getPoinRedeemCoupon.fulfilled]: (state, action) => {
      poinCouponEntity.setAll(state, action.payload)
    },
    [getReportRedeemPoin.fulfilled]: (state, action) => {
      poinCouponEntity.setAll(state, action.payload)
    },
    [getReportRedeemedOnly.fulfilled]: (state, action) => {
      poinCouponEntity.setAll(state, action.payload)
    },
    [revertPoinRedeem.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        poinCouponEntity.updateOne(state, action.payload)
      }
    },
  },
})

export const poinCouponSelector = poinCouponEntity.getSelectors((state) => state.poinCoupon)
export default poinCouponSlice.reducer
