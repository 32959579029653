import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'
import { setCookies, removeCookies } from 'src/helpers/Cookies'

const initialState = {
  user_id: '',
  token: '',
  customer_id: '',
  loading: false,
}

// export const loginUser = createAsyncThunk('user', async (body) => {
//   const res = await axios.post(`${URL_API}/v1/api/web/auth`, body).catch((err) => {
//     // console.log('fail')
//     console.error(err)
//   })

//   setCookies('userLogin', res.data.data)
//   console.log(res.data.data)
//   return await res.data.data
// })

// export const loginUser = createAsyncThunk('user', async (body) => {
//   const res = await axios
//     .post(`${URL_API}/v1/api/web/auth`, {
//       email: body.email,
//       password: body.password,
//     })
//     .catch((err) => {
//       // console.log('fail')
//       console.error(err)
//     })

//   setCookies('userLogin', res.data.data)
//   console.log(res.data.data)
//   return await res.data.data
// })

export const loginUser = createAsyncThunk('user/login', async (credentials) => {
  try {
    // Send a request to authenticate the user
    const response = await axios.post(
      `${URL_API}/v1/api/web/auth`,
      {
        email: credentials.email,
        password: credentials.password,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${token}`,
        },
      },
    )

    // Extract the necessary information from the response
    const userData = {
      user_id: response.data.data.user_id,
      token: response.data.data.token,
      expired_date: response.data.data.expired_date,
      role_list: response.data.data.role_list,
      refresh_token: response.data.data.refresh_token,
      // refresh_expired_date: response.data.data.refresh_expired_date,
      role_group_id: response.data.data.role_group_id,
    }

    // Store the necessary information in the cookie
    setCookies('userLogin', userData)
    // console.log(userData)

    // Return the user ID
    return await response.data.data
  } catch (error) {
    // Handle errors
    console.error('Error:', error)
    throw error // Propagate the error to the Redux store
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addToken: (state, action) => {
      state.token = localStorage.getItem('token')
    },
    addUser: (state, action) => {
      state.user_id = localStorage.getItem('user_id')
    },

    logout: (state) => {
      localStorage.removeItem('token')
      localStorage.removeItem('user_id')
      state.token = null
      state.user_id = null
      removeCookies('userLogin')
    },
  },
  extraReducers: {
    [loginUser.pending]: (state, action) => {
      state.loading = true
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.token = payload.token
      state.user = payload.user_id
      localStorage.setItem('token', payload.token)
      localStorage.setItem('user_id', payload.user_id)
    },
    [loginUser.rejected]: (state, action) => {
      state.loading = true
    },
  },
})

export const { addToken, addUser, logout, userLogin } = authSlice.actions
export default authSlice.reducer
