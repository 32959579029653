import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getRegionOmzet = createAsyncThunk('omzet/omzet-region', async (parameter) => {
  const params = {
    region_id: parameter.region_id,
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    item_category_ids: parameter.item_category_ids,
    item_ids: parameter.item_ids,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/dashboard/omzet/region?`, config)

  return response.data.data.branches == null ? [] : response.data.data.branches
})

export const searchRegionOmzet = createAsyncThunk(
  'omzet/search-omzet-region',
  async (parameter) => {
    const params = {
      region_id: parameter.region_id,
      start_date: parameter.start_date,
      end_date: parameter.end_date,
      item_category_ids: parameter.item_category_ids,
      item_ids: parameter.item_ids,
    }
    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }

    const response = await axios.get(`${URL_API}/v1/api/web/dashboard/omzet/region?`, config)

    return response.data.data.branches == null ? [] : response.data.data.branches
  },
)

const omzetRegionEntity = createEntityAdapter({
  selectId: (omzetRegion) => omzetRegion.branch_id,
})

const omzetRegionSlice = createSlice({
  name: 'omzetRegion',
  initialState: omzetRegionEntity.getInitialState(),
  extraReducers: {
    [getRegionOmzet.fulfilled]: (state, action) => {
      omzetRegionEntity.setAll(state, action.payload)
    },
    [searchRegionOmzet.fulfilled]: (state, action) => {
      omzetRegionEntity.setAll(state, action.payload)
    },
  },
})

export const omzetRegionSelector = omzetRegionEntity.getSelectors((state) => state.omzetRegion)
export default omzetRegionSlice.reducer
