import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//GET LIST MASTER POIN TOKO
export const getPoinToko = createAsyncThunk('poin-toko/list', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    by: 'def.id',
    sort: 'desc',
    customer_id: parameter.customer_id,
    point_type: parameter.point_type,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/point/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

export const getPoinTokoFilter = createAsyncThunk('poin-toko/list', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    by: 'def.id',
    sort: 'desc',
    customer_id: parameter.customer_id,
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    point_type: parameter.point_type,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/point/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data,
    meta: response.data.meta,
  }

  return responseData
})

//LIST SELECT POIN TRANSAKSI
export const getPoinTokoSelect = createAsyncThunk('poin-toko/list-select', async (parameter) => {
  const params = {
    by: 'def.id',
    sort: 'desc',
    customer_id: parameter.customer_id,
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    point_type: parameter.point_type,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/point/select?`, config)

  return response.data.data
})

const poinTokoEntity = createEntityAdapter({
  selectId: (poinToko) => poinToko.id,
})

const poinTokoSlice = createSlice({
  name: 'poinToko',
  initialState: poinTokoEntity.getInitialState(),
  extraReducers: {
    [getPoinToko.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      poinTokoEntity.setAll(state, action.payload)
    },
    [getPoinTokoFilter.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      poinTokoEntity.setAll(state, action.payload)
    },
    [getPoinTokoSelect.fulfilled]: (state, action) => {
      poinTokoEntity.setAll(state, action.payload)
    },
  },
})

export const poinTokoSelector = poinTokoEntity.getSelectors((state) => state.poinToko)
export default poinTokoSlice.reducer
