import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//GET LIST MASTER POIN PROMO
export const getPoinPromo = createAsyncThunk('poin-promo/list', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    by: 'def.id',
    sort: 'desc',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/point_promo/?`, config)

  // get 2 data
  const responseData = {
    data: response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

//LIST SELECT POIN PROMO
export const getPoinPromoSelect = createAsyncThunk('poin-promo/list-select', async (parameter) => {
  const params = {
    by: 'def.id',
    sort: 'desc',
    start_date: parameter.start_date,
    end_date: parameter.end_date,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/point_promo/select?`, config)

  return response.data.data
})

// DETAIL
export const getDetailPoinPromo = createAsyncThunk('poin-promo/detail', async (params) => {
  const response = await axios.get(`${URL_API}/v1/api/point_promo/id/` + params.id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data
})

//ADD POIN PROMO
export const addPoinPromo = createAsyncThunk('poin-promo/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/point_promo/`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data
})

//UPDATE POIN PROMO
export const updatePoinPromo = createAsyncThunk('poin-promo/update', async ({ formdata }) => {
  // console.log('form data', formdata)
  const response = await axios
    .put(`${URL_API}/v1/api/point_promo/` + formdata.id, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data
})

//ADD PHOTO
export const addPoinPromoImage = createAsyncThunk('poin-promo/image-add', async ({ formdata }) => {
  console.log('formdata:', formdata)

  const response = await axios
    .post(`${URL_API}/v1/api/point_promo/photo`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error add')
    })

  return response.data.data
})

//DELETE POIN PROMO
export const deletePoinPromo = createAsyncThunk('poin-promo/Delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/point_promo/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data.id
})

const poinPromoEntity = createEntityAdapter({
  selectId: (poinPromo) => poinPromo.id,
})

const poinPromoSlice = createSlice({
  name: 'poinPromo',
  initialState: poinPromoEntity.getInitialState(),
  extraReducers: {
    [getPoinPromo.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      poinPromoEntity.setAll(state, action.payload)
    },
    [getPoinPromoSelect.fulfilled]: (state, action) => {
      poinPromoEntity.setAll(state, action.payload)
    },
    [getDetailPoinPromo.fulfilled]: (state, action) => {
      poinPromoEntity.setOne(state, action.payload)
    },
    [addPoinPromo.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        poinPromoEntity.addOne(state, action.payload)
      }
    },
    [updatePoinPromo.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        poinPromoEntity.updateOne(state, {
          id: action.payload.id,
          updates: action.payload,
        })
      }
    },
    [deletePoinPromo.fulfilled]: (state, action) => {
      poinPromoEntity.removeOne(state, action.payload)
    },
    [addPoinPromoImage.fulfilled]: (state, action) => {
      poinPromoEntity.addOne(state, action.payload)
    },
  },
})

export const poinPromoSelector = poinPromoEntity.getSelectors((state) => state.poinPromo)
export default poinPromoSlice.reducer
