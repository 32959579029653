import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

// PROMO LIST
export const getPromos = createAsyncThunk('promo/getPromos', async () => {
  const params = {
    by: 'pc.priority',
    sort: 'asc',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/promo/select?`, config)
  return response.data.data.list_promo
})

//FILTER PROMO
export const getPromoFilter = createAsyncThunk('promo/getPromos', async (parameter) => {
  const params = {
    by: 'pc.priority',
    sort: 'asc',
    search: parameter.search,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/promo/select?`, config)
  return response.data.data.list_promo
})

// DETAIL
export const getDetailPromo = createAsyncThunk('promo/detail', async (params) => {
  const response = await axios.get(`${URL_API}/v1/api/web/promo/id/` + params.promo_id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data
})

//ADD PROMO
export const addPromos = createAsyncThunk('promo/addPromos', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/web/promo`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data.list_promo
})

//UPDATE PROMO
export const updatePromo = createAsyncThunk('promo/updatePromo', async ({ formdata }) => {
  let formObject = Object.fromEntries(formdata.entries())
  let formdatavalue = formObject.form_data
  let formData = JSON.parse(formdatavalue)

  const response = await axios
    .put(`${URL_API}/v1/api/web/promo/id/` + formData.promo_id, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data.list_promo
})

//DELETE PROMO
export const deletePromos = createAsyncThunk('promo/Delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/apps/promo/id/` + data.promo_id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data.id
})

const promoEntity = createEntityAdapter({
  selectId: (promo) => promo.promo_id,
})

const promoSlice = createSlice({
  name: 'promo',
  initialState: promoEntity.getInitialState(),
  extraReducers: {
    [getPromos.fulfilled]: (state, action) => {
      promoEntity.setAll(state, action.payload)
    },
    [getPromoFilter.fulfilled]: (state, action) => {
      promoEntity.setAll(state, action.payload)
    },
    [getDetailPromo.fulfilled]: (state, action) => {
      promoEntity.setOne(state, action.payload)
    },

    [addPromos.fulfilled]: (state, action) => {
      if (action.payload && action.payload.promo_id) {
        promoEntity.addOne(state, action.payload)
      }
    },

    [deletePromos.fulfilled]: (state, action) => {
      promoEntity.removeOne(state, action.payload)
    },
    [updatePromo.fulfilled]: (state, action) => {
      if (action.payload && action.payload.promo_id) {
        promoEntity.updateOne(state, {
          promo_id: action.payload.promo_id,
          updates: action.payload,
        })
      }
    },
  },
})

export const promoSelector = promoEntity.getSelectors((state) => state.promo)
export default promoSlice.reducer
