import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//GET LIST MASTER POIN TRANSAKSI
export const getPoinTransaksi = createAsyncThunk('poin-transaksi/list', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    by: 'def.id',
    sort: 'asc',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/point_rules/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data,
    meta: response.data.meta,
  }
  return responseData
})

//LIST SELECT POIN TRANSAKSI
export const getPoinTransaksiSelect = createAsyncThunk('poin-transaksi/list-select', async () => {
  const params = {
    by: 'def.id',
    sort: 'asc',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/point_rules/select?`, config)

  return response.data.data
})

//ADD POIN TRANSAKSI
export const addPoinTransaksi = createAsyncThunk('poin-transaksi/add', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/point_rules/`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data
})

//UPDATE TRANSAKSI
export const updatePoinTransaksi = createAsyncThunk(
  'poin-transaksi/update',
  async ({ formdata }) => {
    // console.log('form data', formdata)
    const response = await axios
      .put(`${URL_API}/v1/api/point_rules/` + formdata.id, formdata, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .catch((err) => {
        // console.log(err, 'error add')
      })

    return response.data.data
  },
)

//DELETE TRANSAKSI
export const deletePoinTransaksi = createAsyncThunk('poin-transaksi/Delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/point_rules/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data.id
})

const poinTransaksiEntity = createEntityAdapter({
  selectId: (poinTransaksi) => poinTransaksi.id,
})

const poinTransaksiSlice = createSlice({
  name: 'poinTransaksi',
  initialState: poinTransaksiEntity.getInitialState(),
  extraReducers: {
    [getPoinTransaksi.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      poinTransaksiEntity.setAll(state, action.payload)
    },
    [addPoinTransaksi.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        poinTransaksiEntity.addOne(state, action.payload)
      }
    },
    [deletePoinTransaksi.fulfilled]: (state, action) => {
      poinTransaksiEntity.removeOne(state, action.payload)
    },
    [updatePoinTransaksi.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        poinTransaksiEntity.updateOne(state, {
          id: action.payload.id,
          updates: action.payload,
        })
      }
    },
    [getPoinTransaksiSelect.fulfilled]: (state, action) => {
      poinTransaksiEntity.setAll(state, action.payload)
    },
  },
})

export const poinTransaksiSelector = poinTransaksiEntity.getSelectors(
  (state) => state.poinTransaksi,
)
export default poinTransaksiSlice.reducer
